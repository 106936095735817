.validate-carrier-appetite-card {
    height: 100%;
    .form-heading {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #414A59;
        margin-top: 4em;
        margin-bottom: 3em;
        text-align: center;
    }

    .validate-carrier-appetite-form {
        width: 100%;

        .text-field {
            margin-bottom: 30px;

            label {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #303030;
            }

            .MuiPopover-paper {
                width: 100px !important;
                height: auto;
                max-height: 200px !important;
            }
        }

    }

    .select-carrier-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .lob-option {
            width: 250px;
            height: 180px;
            background: #FFFFFF;
            border: 1px solid #D9DFEF;
            border-radius: 8px;
            display: flex;
            margin-right: 50px;
            margin-bottom: 50px;
            position: relative;
            align-items: center;
            cursor: pointer;

            .product-icon {
                width: 150px;
            }

            .checkbox-div {
                z-index: 1;
                position: absolute;
                top: 20px;
                right: 20px;
                width: 18px;
                height: 18px;
                opacity: 1 !important;

                .check-icon {
                    color: #166AC4;
                }
            }

            .unchecked-icon {
                color: #BEC2C5;
            }
        }
    }

    .buttonGrid {
        margin-top: 50px;
        margin-bottom: 50px;

        .appetite-submit-button {
            width: 109px;
            height: 44px;
            background: #2196F3;
            border-radius: 100px;
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            color: #FFFFFF;
        }

        .appetite-cancel-button {
            width: 109px;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #2196F3;
            border-radius: 100px;
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            color: #2196F3;
        }
    }

    .select-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #303030;
        margin-bottom: 12px;
        font-family: "Nunito Sans", sans-serif;
    }

    .select-div {
        max-width: 425px;
        font-family: "Nunito Sans", sans-serif;
        margin-top: 10px;
        padding: 10px;
        width: 100%;
        height: 100%;
        min-height: 120px;
        max-height: 129px;
        overflow-y: scroll;
        background: #FFFFFF;
        border: 1px solid #2196F3;
        box-shadow: 0px 0px 15px rgba(224, 231, 236, 0.63);
        margin-bottom: 11px;

        @media (max-width:991px) {
            max-width: 100%;
        }
    }

    .info-label {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 300;
        font-size: 11px;
        line-height: 15px;
        color: #000000;
    }

    .select-wraap {
        width: 100%;
        margin-top: 32px;
    }

    .wc-error-wrap {
        text-align: center;
        padding-top: 8em;

        .wc-error-image {
            width: 500px;
            height: 372.61px;
            margin-bottom: 39px;
        }

        .wc-error-label {
            font-family: 'Nunito Sans';
            font-weight: 400;
            font-size: 24px;
            line-height: 33px;
            color: #414A59;
            padding: 15px;
        }
    }

    .text-elipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;

        @media (min-width:991px) and (max-width:1370px) {
            width: 50%;
        }
    }

    .list-selected {
        padding-inline-start: 0px;

        li {
            &::marker {
                display: none;
            }
        }
    }
    .select-code {
        .select-code-textfield {
            input {
                &::placeholder {
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.87);
                }
            }
        }
        .MuiAutocomplete-clearIndicator {
            display: none;
        }
        .MuiAutocomplete-fullWidth {
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        }
        .MuiChip-root {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 29%;
        }
        .MuiFormControl-fullWidth {
            width: 100%;
            min-height: 30px;
            max-height: 76px;
            border: 1px solid;
            overflow-y: scroll;
            border: 0px;
        }
        .MuiOutlinedInput-notchedOutline {
            border-left: 0px;
            border-right: 0px;
            border-top: 0px;
            border-radius: 0px;
            border-radius: 0px;
            border-bottom: 1px solid transparent;
        }
        .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
            border-left: 0px;
            border-right: 0px;
            border-top: 0px;
            border-radius: 0px;
        }
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
            padding: 0px;
        }
        .MuiInputBase-root {
            font-size: 16px;
        }
    }
}

@media (max-width:991px) {
    .validate-carrier-appetite-card {
        .select-wraap {
            margin-top: 0px;
        }
    }
}
