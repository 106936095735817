/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
  flex-basis: auto; // For IE
  background-color: var(--app-background);
}

.app-wrapper {
  padding: 15px;
  width: 100%;
  @media screen and (max-width: 575px) {
    padding: 20px;
    margin-bottom: 50px;
  }
}

.fast-start-bg {
  background: linear-gradient(180deg, #e6e9eb 42.86%, #c2cce6 100%);
}

.tablet-view {
  @media (min-width: 744px) and (max-width: 991px) {
    margin-bottom: 5em;
  }
}
