.tax-w-form {
  .btn-wrap {
    float: right;
  }
}

.tax-w-p {
  padding-left: 40px !important;
  padding-right: 52px !important;
}

.phone {
  width: 100%;
}

.phone input:disabled {
  color: grey !important;
}

.phone:before {
  display: none;
}

.phone:after {
  display: none;
}

.tax-w-form {
  .disabledField {
    color: grey !important;
  }
}

.btn-file {
  label {
    padding-left: 5px;
    display: block;
  }
}
a {
  text-decoration: none;
  cursor: default;
  &:active {
    color: #fff;
  }
  &:visited {
    color: inherit;
  }
}
.agency-fin-check {
  span {
    padding: 0px;
    margin-right: 4px;
    font-weight: normal;
  }
}
.checked-check-box {
  padding-inline-start: 0px !important;
}
.checked-check-box
  [type='checkbox'].filled-in:not(:checked)
  + span:not(.lever):after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #858383;
  top: 0px;
  z-index: 0;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
}
.checked-check-box
  [type='checkbox'].filled-in:checked
  + span:not(.lever):after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #0084c2;
  background-color: #0084c2;
  z-index: 0;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
}

.custom-control-label {
  cursor: pointer;
}

@media (max-width: 769px) {
  .tax-w-form {
    .custom-control {
      padding-top: 10px;
    }
    .btn-wrap {
      float: none;
      display: flex;
      justify-content: center;
      margin: 10px;
    }
  }
}

.allFien {
  background-color: #fff;
}

.disabled-input {
  cursor: default;
  caret-color: transparent;
}

.taxw9CheckBox {
  &.disabled-lable {
    label {
      span {
        &:last-child {
          color: #8b96b1 !important;
          opacity: 0.5;
        }
        &:nth-child(2) {
          opacity: 0.8;
        }
      }
    }
  }
}
.post-signup-wrapper {
  .post-signup-forms {
    label {
      &.disabled-label {
        color: #8b96b1;
      }
    }
    .disabled-input {
      .MuiInput-input:focus {
        border-bottom: 1px solid #c2cce6 !important;
      }
      .MuiInput-input:hover {
        border-bottom: 1px solid #c2cce6 !important;
      }
    }
  }
}

.visibility { 
  position: absolute;
  right: 8px;
}
