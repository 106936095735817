@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Bold.eot');
  src: url('Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Bold.woff2') format('woff2'),
    url('Gilroy-Bold.woff') format('woff'),
    url('Gilroy-Bold.ttf') format('truetype'),
    url('Gilroy-Bold.svg#Gilroy-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url('flood-icomoon.eot?9uhxhd');
  src: url('flood-icomoon.eot?9uhxhd#iefix') format('embedded-opentype'),
    url('flood-icomoon.ttf?9uhxhd') format('truetype'),
    url('flood-icomoon.woff?9uhxhd') format('woff'),
    url('flood-icomoon.svg?9uhxhd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'icomoon';
  src: url('file-icomoon.eot?9uhxhd');
  src: url('file-icomoon.eot?9uhxhd#iefix') format('embedded-opentype'),
    url('file-icomoon.ttf?9uhxhd') format('truetype'),
    url('file-icomoon.woff?9uhxhd') format('woff'),
    url('file-icomoon.svg?9uhxhd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'icomoon';
  //Dormant
  src: url('dormant-icomoon.eot?9uhxhd');
  src: url('dormant-icomoon.eot?9uhxhd#iefix') format('embedded-opentype'),
    url('dormant-icomoon.ttf?9uhxhd') format('truetype'),
    url('dormant-icomoon.woff?9uhxhd') format('woff'),
    url('dormant-icomoon.svg?9uhxhd#icomoon') format('svg');

  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icomoon';
  //save
  src: url('save/icomoon.eot');
  src: url('save/icomoon.eot?9uhxhd#iefix') format('embedded-opentype'),
    url('save/icomoon.ttf') format('truetype'),
    url('save/icomoon.woff') format('woff'),
    url('save/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icomoon';

  src: url('close-icomoon.eot?9uhxhd');
  src: url('close-icomoon.eot?9uhxhd#iefix') format('embedded-opentype'),
    url('close-icomoon.ttf?9uhxhd') format('truetype'),
    url('close-icomoon.woff?9uhxhd') format('woff'),
    url('close-icomoon.svg?9uhxhd#icomoon') format('svg');

  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'icomoon';

  src: url('quote-icomoon.eot?9uhxhd');
  src: url('quote-icomoon.eot?9uhxhd#iefix') format('embedded-opentype'),
    url('quote-icomoon.ttf?9uhxhd') format('truetype'),
    url('quote-icomoon.woff?9uhxhd') format('woff'),
    url('quote-icomoon.svg?9uhxhd#icomoon') format('svg');

  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'icomoon';

  src: url('credential-icomoon.eot?9uhxhd');
  src: url('credential-icomoon.eot?9uhxhd#iefix') format('embedded-opentype'),
    url('credential-icomoon.ttf?9uhxhd') format('truetype'),
    url('credential-icomoon.woff?9uhxhd') format('woff'),
    url('credential-icomoon.svg?9uhxhd#icomoon') format('svg');

  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?eo8msu');
  src:  url('icomoon.eot?eo8msu#iefix') format('embedded-opentype'),
    url('icomoon.ttf?eo8msu') format('truetype'),
    url('icomoon.woff?eo8msu') format('woff'),
    url('icomoon.svg?eo8msu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;

  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-Frame:before {
    content: "\e907";
    color: #566276;
    font-size: 14px;
    vertical-align: initial;
}
.icon-LOB-icon:before {
  content: "\e908";
  color: #566276;
}
.icon-code:before {
  content: '\e900';
  font-size: 12px;
}
.icon-lock:before {
    content: '\e902';
    font-size: 20px;
    vertical-align: middle;
}
.icon-username:before {
  content: "\e903";
  color: #566276;
  font-size: 19px;
  vertical-align: bottom;
}
/* .icon-key:before {
  content: "\e901";
} */
.icon-lock:before {
  content: "\e902";
}
.icon-username:before {
  content: "\e903";
  color: #566276;
}
.icon-fileIcon:before {
  content: '\e918';
}
.icon-dormant:before {
  content: '\e919';
  font-size: 16px;
}
.icon-saveicon:before {
  content: '\1f32b';
  font-size: 16px;
  vertical-align: middle;
}
.icon-home:before {
  content: '\e900';
}
.icon-wc:before {
  content: '\e901';
  font-size: 19px;
}
.icon-tend:before {
  content: "\e90b";
  font-size: 19px;
  color: transparent;
}
