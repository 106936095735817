.email-verification,
.verified-email {
  .MuiButton-root {
    text-transform: initial;
  }

  .app-main-content {
    display: flex;
  }
  .card-content {
    max-width: 575px;
    padding: 20px 50px;
  }

  .verification-form {
    padding-bottom: 16px !important;
    .congj-p {
      font-family: 'open sans semibold';
      font-size: 20px;
      color: #0084c2;
      margin-bottom: 10px;
    }
    p {
      font-family: 'open sans semibold';
      font-size: 16px;
    }
    h6 {
      font-size: 30px !important;
    }
    .blue-color {
      color: #0084c2;
      cursor: default;
    }
    .login-btn {
      font-size: 1.5rem;
      color: #0084c2 !important;
    }
  }

  .btn-email {
    color: #fff !important;
    padding: 0px;
    padding: 4px 40px;
    margin-top: 15px;
    font-weight: 400;
    border-radius: 4px;
    font-family: 'open sans semibold';

    background: #0084c2 !important;
  }
}
