.progress-bar-inerwrap {
  display: flex;
  align-items: center;
  .progress-label {
    color: #333333;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    svg {

      font-size: 14px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .info-icon {
    color: #c2c2be;
  }

  .MuiPaper-root {
    box-shadow: none !important;
    background-color: transparent;
  }
  .MuiStepper-root {
    padding: 0px;
  }
  .MuiStep-horizontal {
    padding-left: 27px;
    padding-right: 27px;
  }
  .MuiStepConnector-alternativeLabel {
    top: 12px;
    left: calc(-60% + 14px);
    right: calc(40% + 15px);
    position: absolute;
  }
}
