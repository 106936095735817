.pre-signup-wrapper {
  .sign-up-form {
    .row {
      margin-bottom: 13px;
    }
  }
  #sign-up-form-field {
    .card-content {
      padding: 35px 65px;
    }
  }
  .field-bld {
    input[type='text']:not(.browser-default) {
      font-family: 'Nunito Sans', sans-serif;
      font-size: 15px;
    }
    .input-field {
      margin-bottom: 1rem;
    }
    .heading {
      h6 {
        font-size: 30px;
        font-family: "Nunito Sans",sans-serif;
      }
    }
  }
  .phone_number {
    width: 100%;
  }
  #password {
    font-size: 20px;
  }
  span.mat-icon {
    position: relative;
    left: 8px;
    top: -5px;
    cursor: default;
  }
  .sign-up-head {
    color: #0084c2;
    font-family: 'Nunito Sans', sans-serif;
    margin-bottom: 10px;
  }
  .heading h6 {
    font-size: 26px;
    font-family: 'Nunito Sans', sans-serif;
  }
  .heading {
    padding-bottom: 30px;
  }
  .btn-submit {
    width: 100%;
    max-width: 200px;
    text-transform: capitalize;
    background: #0084c4;
    margin-top: 14px;
    font-weight: 400 !important;
    color: #fff !important;
    margin-top: 30px !important;
    padding: 6px !important;
    display: block;
    margin: 0 auto;
  }
  @media (min-width: 320px) and (max-width: 484px) {
    .sign-up-form {
      .capacha-wraper {
        iframe {
          transform: scale(0.77);
          -webkit-transform: scal(0.77);
        }
      }
    }
  }
  @media (max-width: 767px) {
    .heading h6 {
      font-size: 20px;
    }
    .error {
      margin-bottom: 20px;
    }
  }
  .capacha-wraper {
    margin-top: 25px;
  }
  .error {
    position: relative;
    width: 100%;
    height: 0px;
    top: 0px;
    left: 0px;
    color: red;
    font-size: 12px;
    text-align: left;
  }

  .phone_number:before {
    width: 100%;
  }

  .phone_number:before {
    display: none;
  }

  .phone_number:after {
    display: none;
  }
}
@media (max-width: 991px) {
  .pre-signup-wrapper {
    #sign-up-form-field {
      .card-content {
        padding: 35px 25px;
      }
    }
  }
}
