#header__new-quote-button {
  padding: 0;
  padding-inline-start: calc(16px - 1px);
  padding-inline-end: 24px;
  height: 32px;
  display: inline-flex;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid transparent;

  color: white;
  background-color: #4696EC;

  &:hover {
    background-color: #2382E8;
  }
  &:disabled, &:hover:disabled {
    background-color: #CCCCCC;
    color: #F2F2F2;
  }
  &:active {
    background-color: #5DA3EE;
  }
  &:focus-visible {
    outline: 2px solid #4696EC;
  }

  & > span {
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    line-height: 140%;
    font-weight: 400;
    text-transform: uppercase;
  }

  & > svg {
    width: 20px;
    height: 20px;
  }
}

header.app-main-header > div {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}