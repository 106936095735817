.pill-label {
  align-items: center;
  background: #4696ec;
  border-radius: 4px;
  color: #ffffff;
  display: inline-flex;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  padding: 2px 6px;
  text-transform: uppercase;
}
