@import "../../../../styles/color-theme-variable.scss";

h6 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 20px;
}
.height-20 {
  height: 20px;
}
.first-step {
  .ssnInfoMargin {
    margin-left: 9px;
    width: 14px;
    height: 14px;
    margin-top: -5px;
  }
}
.margin-top-8 {
  margin-top: 6%;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.agency-p p {
  margin-bottom: 1.5rem;
}
.phone {
  width: 100%;
}
.details-capacha-s {
  .p-10 {
    padding: 16px;
  }
}


.phone:before {
  display: none;
}
.btn-wrap {
  float: right;
}

.phone:after {
  display: none;
}

.select-dropdown {
  color: #373737;
}

.select-dropdown:disabled {
  color: grey !important;
}

//checkbox css change
[type='checkbox'].filled-in:disabled:not(:checked) + span:not(.lever):after {
  border: 1px solid #858383;
  background-color: transparent;
}

.selectInfoIconWrapper {
  display: -webkit-box;
  .tooltipIcon {
    position: relative;
    top: 12px;
    right: -9px;
  }
}

.ssnInfoIcon {
  display: flex;
  .ssnInfoMargin {
    margin: auto;
  }
}

.custom-control-label {
  cursor: pointer;
}

.pre-signup-wrapper .text-optin-label [type='checkbox'] + span:not(.lever),
.post-signup-wrapper .text-optin-label [type='checkbox'] + span:not(.lever) {
  font-size: 14px;
  cursor: default;
}

.checked-check-box.text-optin-label
  [type='checkbox'].filled-in:not(:checked)
  + span:not(.lever):after {
  top: 2px;
}

.checked-check-box.text-optin-label
  [type='checkbox'].filled-in:checked
  + span:not(.lever):after {
  top: 2px;
}
.post-signup-forms{
  .agency-profile-content{
    padding-left: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: #525252;
    margin-bottom: 24px;
  }
  .hint-info{
    font-size: 11px;
    color: #A5A4A1;
    &.errorMsg{
      margin-top:-4px;
      display: block;
    }
  }
  .sub-head-wrap{
    padding: 12px 16px;
  }
  .padding-left-10 {
    padding-left: 10px;
  }
  .subhead{
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #525252;
  }
  .password-wrap{
    span.mat-icon {
      position: relative;
      left: 8px;
      top: -1px;
      cursor: default;
      svg {
        color:#FF9A14;
      }
    }
    .password-hint{
      font-size: 11px;
      color: #A5A4A1;
    }
    .MuiInput-underline{
      &:before{
        border-bottom: 1px solid #ccc;
      }&:hover:not(.Mui-disabled)::before{
        border-width: 1px;
        border-color: #0084c2;
      }&:after{
        border-width: 1px;
        border-color: #0084c2;
      }
      width: 100%;
    }
  }
  .agency-name-field,
  .password-field {
    position: relative;
    .info-icon {
      position: absolute;
      top: 0px;
      right: -11px;
      cursor: pointer;
      svg {
        font-size: 14px;
        color:#FF9A14;
      }
    }
  }
}
.capacha-wraper {
  flex-direction: column;
  margin-bottom: 25px;
  justify-content: flex-end;
  z-index: 1;
}
.recaptcha {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 10px; // Remove the height when use visible captcha
  .recaptcha-error {
    color: #f44336;
    width: 300px;
    height: 0px;
    margin-bottom: 20px;
  }
}
.agency-social-proof-wrap {
  max-width: 260px;
  margin-left: auto;
  background: #FFF5E9;
  padding: 24px 20px;
  height: 100%;
  position: relative;
  z-index: 0;
  color: #525252;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 0px;
  }
  & > div.agency-social-proof-card:nth-child(2) {
    border-top: 0;
  }
  & > div.agency-social-proof-card:last-child {
    border: 0px;
    padding-bottom: 0px;
  }
  .social-heading{
    margin-bottom: 0px;
    font-weight: 700;
    color: #525252;
    font-size: 14px;
  }
  .agency-social-proof-card{
    padding: 21px 0px;
    border-bottom:1px solid #DFDFDA;
    
    .agency-social-proof-icon {
      margin-bottom: 10px;
      img{
        width:28.8px;
        height:28.8px;
      }
    }
    .agency-social-proof-content {
      font-family: 'Nunito Sans';
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #525252;
    }
  }
}
.fast-start-wrap {
  padding: 32px 20px;
  height: 100%;

  .fast-start-content-wrap {
    margin-top: 40px;
    .fast-start-content {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 12px;
      p{
        font-size: 12px;
      }
      .bold-text{
        font-size: 21px;
        color: #FF9A14;
        font-weight: 900;

      }
      .break-text{
        display: block;
      }
      .semibold{
        font-weight: 800;
      }
    }
  }
}
@media (max-width: 767px) {
  .step-one-radio-button {
    .custom-control {
      padding-top: 10px;
    }
  }
  .selectInfoIconWrapper {
    .tooltipIcon {
      right: -4px;
    }
  }
  .capacha-wraper {
    width: 100%;
    iframe {
      transform: scale(0.50);
    -webkit-transform: scale(0.90);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    }
  }
}

.visibility { 
  position: absolute;
  right: 8px;
}
