$light-grey : #333333;
$orangeColor:#FF8B1F;
$white:#FFFFFF;
$fontfamily: 'Nunito Sans';
.label-span-style {
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}
.logo-span-style {
  width: 12px;
  margin-top: 1px;
}

.cse-button {
  width: 96px;
  height: 40px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 16px;
  text-transform: uppercase;
}
.cross-sell-wraper {
  padding-left: 14px;
  padding-right: 14px;
  .orange-lbl {
    color: $orangeColor;
    font-family: $fontfamily;
    .logo-span {
      @extend .logo-span-style;
     }
     .label-span {
       @extend .label-span-style;
     }
  }
  .carrier-para {
    display: inline-flex;
    .logo-span {
     @extend .logo-span-style;
     &.icon-flood-cse{
      width: 14px;
    }
    }
    .label-span {
      @extend .label-span-style;
    }
  }
  .mr-6 {
    margin-right: 7px;
  }
  .label-style {
    font-size: 12px;
    line-height: 120%;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: $fontfamily;
  }
  .font-700 {
    font-weight: 700;
  }
  .purchase-lbl-style {
    font-size: 16px;
    line-height: 120%;
    font-family: $fontfamily;
  }
  .lbl-back {
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $light-grey;
    text-transform: capitalize !important;
    svg {
      width:16px;
      margin-right:7px;
    }
    &:hover {
      background-color: transparent;
    }
  }
  .first-wrap {
    margin-bottom: 8px;
    img {
      width: 40px;
      height: 22px;
      object-fit:contain;
    }
 
  }
  .black-card {
    background: $light-grey;
    border: 1px solid #e0e0e0;
    .carrier-label {
      @extend .label-style;
      color: $white;
    }
    @extend .first-wrap;
    .lbl {
      color: blue;
    }
    .purchase-lbl {
      @extend .purchase-lbl-style;
      color: $white;
    }
  }
  .white-card {
    background: $white;
    border: 1px solid #e0e0e0;
    .carrier-label {
      @extend .label-style;
      color: #4f4f4f;
    }
    @extend .first-wrap;
    .lbl {
      color: #4f4f4f;
    }
    .purchase-lbl {
      @extend .purchase-lbl-style;
      color: #4f4f4f;
    }
  }
  .cross-sell-card {
    width: 100%;
    min-height: 104px;
    border-radius: 8px;
    padding: 10px 16px;
    cursor: pointer;

    .cross-sell-button {
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      color: $light-grey;
      min-width: 81px;
      max-width: 98px;
      height: 32px;
      background: $white;
      border: 1px solid #f2f2f2;
      border-radius: 20px;
      &:hover,
      &:focus {
        background: $white;
        border: 1px solid #f2f2f2;
        box-shadow: none;
      }
    }
  }
  .label-suggested {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $light-grey;
    margin-top: 20px;
    margin-bottom: 12px;
    font-family: $fontfamily;
  }
  .commision-label {
    font-family: 'Nunito Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: #4F4F4F;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .black {
    color: $white;
  }
}
.go-back-modal {
  position: relative;
  .MuiDialog-paper {
    border-radius: 10px;
  .cse-close-icon {
    color: $light-grey;
    position: absolute;
    right: 26px;
    top: 22px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .go-back-lbl {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: $light-grey;
    margin-top: 56px;
    margin-bottom: 56px;
    text-align: center;
    font-family: $fontfamily;
  }
  .cse-white-button {
    @extend .cse-button;
    background: $white;
    border: 1px solid #F2F2F2;
    color: #333333;
  }
  .cse-grey-button {
   @extend .cse-button;
    background: #4F4F4F;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: $white;
    &:hover {
      background: #4F4F4F;
    }
  }
}
}