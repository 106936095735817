.change-password-dialog-content>.MuiDialog-container>.MuiDialog-paperFullWidth {
    width: 23rem;
    height: 33rem;

    .button {
        width: 96px;
        height: 40px;
    }

    .button-password {
        @extend .button;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-top: 16px;
        margin-bottom: 16px;
      
    }

    .btn-grey {
        @extend .button;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        &:hover {
            cursor: default;
        }
    }
}

.update-pass-dialog {
    .message-label {
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: #525252;
    }

    .btn-done {
        width: 83px;
        height: 40px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;

       
    }
}