@import '../colorTheme/theme-navy-blue';
@import '../colorTheme/theme-sky-blue';
@import '../colorTheme/theme-purplish-blue';
@font-face {
  font-family: 'icomoon';
  //save
  src: url('../../fonts/ss/icon.eot');
  src: url('../../fonts/ss/icon.eot?9uhxhd#iefix') format('embedded-opentype'),
    url('../../fonts/ss/icon.ttf') format('truetype'),
    url('../../fonts/ss/icon.woff') format('woff'),
    url('../../fonts/ss/icon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'icomoon';
  //save
  src: url('../../fonts/stateicon/stateicon.eot');
  src: url('../../fonts/stateicon/stateicon.eot?9uhxhd#iefix')
      format('embedded-opentype'),
    url('../../fonts/stateicon/stateicon.ttf') format('truetype'),
    url('../../fonts/stateicon/stateicon.woff') format('woff'),
    url('../../fonts/stateicon/stateicon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
* {
  font-family: 'Nunito Sans', sans-serif;
}
$blueColor: #2196f3;
$greycolorText: #404958;
$mediumgrey: #6b7a93;
$lightgrey: #566276;
$textgrey: #8995a9;
$redcolor: #f44336;
$grey: #ccc;
$greentext: #4caf50;
$white: #fff;
$osloGray: #96999c;
$font-20: 20px;
$font-18: 18px;
$font-16: 16px;
$font-14: 14px;
$font-12: 12px;
$fontFamily: 'Nunito Sans', sans-serif;
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
label,
span,
p,
.MuiChip-root,
.MuiInputBase-root,
.MuiFormLabel-root,
.MuiTypography-root {
  font-family: $fontFamily;
}
.tier-opt-lbl {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70px;
}
a {
  cursor: pointer !important;
}
.pointer-event {
  pointer-events: none;
}
.MuiFormLabel-asterisk {
  color: $redcolor;
}
.input-wrap {
  .MuiInput-input {
    padding: 0px;
  }
}
.MuiButton-contained {
  background-color: $white;
}
.text-black {
  color: #000;
}
.icon-font {
  font-size: 1.25rem;
}
.link-label {
  font-size: $font-14 !important;
}
.font-weight {
  font-weight: bold !important;
}
.text-green {
  color: $greentext !important;
}
.MuiCard-root,
.MuiPaper-root {
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.04) !important;
}

.text-red {
  color: $redcolor !important;
}
.icon-tend {
  &::before {
    background-image: url('../../assets/images/tend.svg');
    font-size: 20px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 14px;
  }
}
.icon-Align-Top:before {
  content: '\e902';
  font-size: 20px;
}
.icon-Align-bottom:before {
  content: '\e900';
  @extend .icon-Align-Top:before;
}
.icon-Align-middle:before {
  content: '\e901';
  @extend .icon-Align-Top:before;
}
.icon-code:before {
  content: '\e904';
  color: #566276;
}
.icon-lock:before {
  content: '\e903';
  color: #566276;
}
.icon-username:before {
  content: '\e905';
  color: #566276;
}
.icon-state:before {
  content: '\21';
  color: #566276;
  font-size: 18px;
}
.icon-floods:before {
  content: '\22';
  font-size: 12px;
  vertical-align: top;
}
.icon-actionicon:before {
  content: '\23';
  color: #566276;
}
.icon-home:before {
  content: '\25';
}
.icon-cyber:before {
  content: '\26';
}
.icon-flood:before {
  content: '\22';
}
.icon-download:before {
  content: '\27';
  color: #4d4d4c;
  margin-right: 8px;
  font-size: 14px;
}
.icon-upload-icon:before {
  content: '\28';
  color: #4d4d4c;
  margin-right: 8px;
  font-size: 14px;
}
.icon-calender-icon:before {
  content: '\29';
  color: #4d4d4c;
  font-size: 22px;
}
.icon-flood-cse:before {
  content: '\30';
}
.icon-Logout:before {
  content: '\31';
  font-size: 22px;
  margin-right: 6px;
}
.icon-Bell-1:before {
  content: '\32';
}
.icon-view-agent:before {
  content: '\33';
}
.icon-user-lock:before {
  content: '\34';
  font-size: 14px;
  color: orange;
  position: absolute;
  bottom: 16px;
  left: 39px;
}
.text-yellow {
  color: #e1cf30;
}

.text-grey {
  color: #6c757d;
}
.iconwidth {
  width: 18px !important;
  cursor: pointer;
}
.change-history-icon {
  transform: rotate(90deg);
}
.new-label {
  width: 37px;
  height: 22px;
  background: #4696EC;
  border-radius: 4px;
  color: #fff;
  margin-left: 8px;
  padding: 4px 6px;
}
.divider {
  border-bottom: 1px solid #e0e0e0;
}
.divider-0 {
  border-bottom: none;
}
.pb-24 {
  padding-bottom: 24px;
}
.lock-icon {
  font-size: 20px !important;
  margin-right: 10px;
}

.blue-button {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 120px;
  height: 40px;
  line-height: 15px;
  color: #fff;
  border: 0px;
  font-size: 12px;
  border: 0px;
}
.white-button {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 12px 7px rgba(234, 238, 241, 0.3);
  border-radius: 5px;
  width: 120px;
  height: 40px;
  line-height: 15px;
  img {
    width: 15px;
    margin-right: 5px;
    height: 17px;
  }
}
.white-button-quote {
  color: #2196f3 !important;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 12px 7px rgba(234, 238, 241, 0.3);
  border-radius: 5px;
  font-size: $font-12 !important;
}

.shodow-none {
  box-shadow: none !important;
}

.w-20 {
  width: 20px;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.center-item {
  display: flex;
  justify-content: center;
}
.yellow-clr {
  background-color: #e1cf30;
}
.error-msg {
  color: $redcolor;
  position: relative;

  width: 100%;
  height: 0px;
  top: 0px;
  margin-bottom: 20px;
  left: 0px;
}
.loader {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.row-edit-button,
.row-show-button {
  &:hover {
    background-color: transparent !important;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e1e4e9;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(177, 176, 176);
  border-radius: 10px;
}

.settingWrapper,
.AgentCommissionWrapper {
  .MuiPaper-elevation2 {
    box-shadow: none !important;
  }
}
.marginbottom {
  margin-bottom: 20px !important;
}
.text-grey {
  color: $textgrey;
}

.nodataLbl {
  font-size: $font-14;
}
.MuiInputLabel-formControl {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent;
  padding-left: 0px !important;
  &:hover {
    background-color: transparent;
  }
}
.orangeBtn {
  color: orange !important;
  padding: 0px !important;
  font-size: $font-14;
  font-weight: bold;
}
.org-btn-wrap {
  .MuiButton-root.Mui-disabled {
    background-color: transparent;
    box-shadow: none;
  }
}
.app-signup-v {
  .document-btn-file {
    min-width: 170px;
    font-size: $font-12;
    input {
      display: contents;
    }
    .file {
      color: #0084c2;
      padding-left: 12px;
      line-height: 24px;
      background-color: #ffffff;
    }
    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile + label {
      max-width: 100%;
      white-space: nowrap;
      cursor: pointer;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .inputfile:active + label {
      color: #fff;
      .filename {
        color: #fff;
        transition: 0.3s ease-out;
      }
    }
    .inputfile:focus + label,
    .inputfile.has-focus + label {
      outline: none;
      outline: none;
    }
  }
}
.btn-file label {
  font-size: $font-14;
}
.verticleHorizontalcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-img-width {
  width: 26px;
  height: 26px;
}
.editIconCls {
  color: #8995a9;
  cursor: pointer;
  padding: 5px;
  font-size: 27px;
  margin-left: 10px;
  background: #f0f2f4;
  border-radius: 13px;
}
.MuiCollapse-wrapperInner {
  font-family: $fontFamily;
}
.motion-auto-wrap {
  .MuiCollapse-wrapperInner {
    .loading-label {
      color: #575757;
      font-size: $font-16;
    }
  }
}
.documentlbl {
  font-size: $font-16;
  font-weight: bold;
  color: $greycolorText;
}
.deleteFontIcon {
  color: #8995a9;
  font-size: $font-20;
}
.centeralign {
  justify-content: flex-end;
}
button,
p {
  font-family: $fontFamily;
  color: $greycolorText;
}
.purchansebtn {
  border-radius: 5px !important;
  color: $white !important;
  font-size: $font-16 !important;
  box-shadow: none !important;
}
.greytext {
  color: $lightgrey;
}
.subagent-title {
  .MuiTypography-h6 {
    font-size: $font-18 !important;
  }
}
.MuiTableCell-root {
  font-family: $fontFamily !important;
  color: $lightgrey !important;
}
.quoteTable {
  th,
  td {
    padding: 16px !important;
    white-space: nowrap;
  }
}
.MuiTypography-h6 {
  @extend p;
  font-weight: bold !important;
  font-size: 16px;
}
.MuiDialogTitle-root {
  .MuiTypography-h6 {
    font-size: 16px !important;
  }
}
.mbt-10 {
  margin: 10px 0px;
}
.MuiDialogContentText-root,
.MuiDialogContent-root {
  p {
    font-size: $font-14;
  }
}
.borderGrey {
  border: 1px solid #e1e4e9;
  border-radius: 5px;
  padding: 10px;
}

.MuiTableCell-head {
  @extend .MuiTypography-h6;
  font-size: $font-16;
}
.MuiChip-root {
  border-radius: 4px !important;
}
.btnWidth {
  width: 90px;
  font-size: $font-14;
  text-overflow: inherit;
}
.white-bg {
  background: #fff !important;
  font-weight: bold;
}
.recharts-wrapper {
  width: 100% !important;
  height: auto !important;
  svg {
    width: 100%;
  }
}

.bold {
  font-weight: bold !important;
}
.p-30 {
  padding: 30px;
}
.box-shodow {
  box-shadow: 0px 4px 26px #0000000f;
}
.badge {
  font-weight: 400;

  font-size: $font-14 !important;
  line-height: inherit;

  border-radius: 0.125rem;
}
.text-black {
  color: #000;
}

.pb-16 {
  padding-bottom: 16px !important;
}
.notification-dot-div {
  width: 10px;
  height: 10px;
  padding: 5px;
  margin-right: 9px;
}
.top-right-12 {
  top: 12px;
  right: 12px;
}
.table-column-img {
  width: 60px;
  height: 40px;
  object-fit: contain;
}
.table-skeleton {
  margin-top: -1.4em;
  margin-bottom: 0;
}
.notification {
  word-wrap: break-word;
}

.login-container {
  .fieldError {
    position: relative;
    width: 100%;
    height: auto;
    top: 0px;
    left: 0px;
    font-size: 13px;
    color: $redcolor;
  }
  .forgotLink {
    float: right;
    padding-top: 0px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

//pennymac-landing-page
.landing-page {
  .margin-top-7 {
    margin-top: 12em;
  }
  .margin-top-15 {
    margin-top: 13em;
  }
  .logo-img {
    max-width: 400px;
  }
  .forget-pwd-div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  .login-heading {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
    font-family: 'Gilroy-Bold';
    img {
      margin-left: 15px;
    }
  }
  .text-field {
    .MuiTextField-root {
      margin-bottom: 20px;
    }
    .MuiOutlinedInput-root {
      border-radius: 25px;
    }
  }
  input[type='text'] {
    border-bottom: none !important;
  }

  .button-login {
    box-shadow: none !important;
    border-radius: 25px;
    color: #fff;
    min-width: 150px;
    font-size: 15px;
    font-weight: 500;
    &:hover {
      box-shadow: none !important;
      border-radius: 25px;
      color: #fff;
    }
  }
  .frame-img {
    max-width: 683px;
    height: 528px;
  }
  .green-home-img {
    margin-left: 12em;
    height: 487px;
    margin-top: 3em;
  }
  .margin-t-5 {
    margin-top: 5em;
  }
}
.AS-form-wrap {
  input {
    padding: 15px 18px;
  }
}
.next-sso-error {
  a {
    &:hover {
      cursor: pointer;
    }
  }
}
.filter-dot {
  font-size: 20px;
}

.bg-color {
  background: linear-gradient(360deg, #dff0ff 5.9%, #ffffff 43.9%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.cred-inst-link {
  text-decoration: underline;
  .link-lbl {
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-size: 12px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  header {
    .app-logo1 {
      width: 180px !important;
    }
    .blue-button {
      margin: 0 auto;
      width: auto;
      min-width: 20px;
      .header-blue-lbl {
        display: none;
      }
    }
  }
  .p-30 {
    padding: 0px;
  }
  .centeralign {
    justify-content: center !important;
  }
  .iconwidth {
    width: 18px !important;
    cursor: pointer;
  }

  .MuiTableCell-head {
    font-size: 13px;
  }
  .mobmarginbottom {
    margin-bottom: 20px;
  }
  .MuiTableCell-body {
    font-size: $font-12 !important;
  }
  .subagent-title {
    .MuiTypography-h6 {
      font-size: $font-16 !important;
    }
  }
  .documentlbl {
    font-size: $font-12;
  }
  .font-12 {
    font-size: $font-12 !important;
  }
}
.iconwidth-excel {
  width: 18px !important;
  cursor: pointer;
}
.grecaptcha-badge {
  /*
    Hide the badge on all pages
    It is restored on sign in and sign up page programatically
  */
  visibility: hidden;
  z-index: 1;
}

.href-wrap {
  display: block;
  padding: 16px;
  color: inherit !important;
}
.hover-row {
  table {
    tbody {
      tr {
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}

.href-wrap:hover {
  text-decoration: none;
}
@media (max-width: 576px) {
  .btnWidth {
    font-size: $font-12;
  }
}
@media (min-width: 576px) and (max-width: 1700px) {
  .btnWidth {
    font-size: $font-12;
  }

  .link-label {
    font-size: $font-12 !important;
  }

  .iconwidth {
    width: 18px !important;
    cursor: pointer;
  }

  .purchansebtn {
    font-size: $font-12 !important;
  }
  .font-12 {
    font-size: $font-12 !important;
  }
  .editIconCls {
    font-size: 23px;
  }
  .subagent-title {
    .MuiTypography-h6 {
      font-size: $font-16 !important;
    }
  }
  .eyeIcon {
    font-size: 16px !important;
  }

  .nodataLbl {
    font-size: $font-12 !important;
  }

  .badge {
    font-size: $font-12 !important;
  }

  .orangeBtn {
    font-size: $font-12;
  }
  .MuiTableCell-head {
    font-size: $font-12 !important;
    line-height: $font-16;
  }

  .MuiTableCell-body {
    font-size: $font-12 !important;
  }
  .documentlbl {
    font-size: $font-12;
  }
  .deleteFontIcon {
    color: #8995a9;
    font-size: $font-18;
  }
}
@media (max-width: 556px) {
  .mob-margin-top {
    margin-top: 8px;
  }
}
@media (max-width: 600px) {
  .blue-button {
    height: 30px;
    margin-top: 8px;
  }
  .mob-margin-bootom {
    margin-bottom: 30px;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .margin-bottom-16 {
    margin-bottom: 16px;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  ul.nav-menu li button,
  ul.nav-menu li a {
    font-size: $font-14;
  }
  .user-detail {
    h4 {
      font-size: $font-14;
    }
  }
}
@media (max-width: 991px) {
  .mobmargin-top-16 {
    margin-top: 16px;
  }
}

@media (max-width: 1200px) {
  .display-flex {
    display: flex;
  }
}
@media (min-width: 1400px) {
  .MuiDialogTitle-root {
    .MuiTypography-h6 {
      font-size: $font-18 !important;
    }
  }
  .MuiDialogContentText-root {
    p {
      font-size: $font-16;
    }
  }
}
@supports (-ms-ime-align: auto) {
  .MuiButton-textPrimary {
    &:hover {
      background: $blueColor !important;
    }
  }
}
