$light-grey: #333333;
$orangeColor: #ff8b1f;
$greyColor: #828282;

.cross-sell-welcome-modal {
  .MuiDialog-paper {
    border-radius: 10px;
    .welcome-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: $light-grey;
      text-align: left;
      margin-bottom: 25px;
    }

    .MuiDialogContent-root {
      &.welcome-wrap {
        padding: 4em 3em 3em 4em;

        .welcome-content {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: $light-grey;

          .font-bold {
            font-weight: 700;
          }
        }
      }
    }
  }

  .get-started-btn {
    width: 129px;
    height: 40px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin: 16px 24px;
    text-transform: uppercase !important;
    color: #fff;
    background-color: $orangeColor;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    &:hover {
      background-color: $orangeColor;
    }
  }
}

.reminderTooltipWrap {
  @media (max-width: 767px) {
    position: relative;
  }
  .reminderTooltip {
    z-index: 10000;
    width: 230px;
    background: $greyColor;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    top: 238px;
    left: 240px;
    margin-left: 40px;
    @media (max-width: 767px) {
      left: 0px;
      top: 80px;
    }
    &.show {
      opacity: 1;
      animation: 0.5s ease-out 0s 1 slideInLeft;
    }
    &.hide {
      opacity: 0;
      animation: 0.5s ease-out 0s 1 fadeOut;
    }

    p {
      font-size: 13px;
      color: #fff;
      max-width: 140px;
      word-break: break-word;
      font-family: "Nunito Sans";
    }
    .circleArrow {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      margin-right: 9px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $orangeColor;
      img {
        max-width: 8px;
        margin-left: 2px;
      }
    }
    .close-icon {
      align-self: flex-start;
      cursor: pointer;
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
}
