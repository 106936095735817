/*Login Styles*/

.login-container {
  position: relative;
  height: 100%;

  .error {
    color: red;
  }
  .app-main-container {
    background-color: var(--app-background);
  }
  .btn-login {
    background: #0084c2;
    border: 1px solid #0084c2;
    color: #fff;
    width: 100% !important;
    max-width: 244px;
    float: left !important;
    margin-left: 0px !important;
    border-radius: 2px;
    font-weight: bold;
    cursor: pointer;

    &:active {
      background: #0084c2 !important;
      border: 1px solid #0084c2 !important;
    }

    &:disabled {
      opacity: 0.65;
      cursor: default;

      &:hover {
        background: #0084c2 !important;
        border: 1px solid #0084c2 !important;
      }
    }
  }

  .card-content {
    a {
      color: #0084c2;
      font-family: $fontFamily;
    }
  }
}

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(4px);
  font-size: 14px;
  max-width: 94%;
}

.login-header {
  margin-bottom: 30px;
}

.login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}

.app-login-main-content {
  @include display-flex();
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(10px);
  font-size: 14px;
  overflow: hidden;
}

.app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
  order: 1;

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 2;
  }
}

.app-login-header {
  margin-bottom: 30px;
}

.app-login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.app-login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-logo-content {
  background-color: $app-primary;
  padding: 35px 35px 20px;
  width: 40%;
  order: 2;

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 1;
  }
}

.app-social-block {
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);

  & .social-link,
  & .social-link li {
    margin: 0;
  }

  & .social-link button {
    border: solid 1px $app-primary;
    @include border-radius(50%);
    color: $app-primary;
    padding: 0;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary;
    }
  }

  & p {
    margin-bottom: 0;
  }
}
