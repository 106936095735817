/*Error Styles*/

.page-error-container {
  position: relative;
  padding: 30px 0;
  height: 100%;
  background-color: $white;

  .section {
    height: 100%;
    display: table;
    width: 100%;
    padding-bottom: 0 !important;
    padding-top: 0 !important;

    .wrapper {
      width: 100%;
      display: table-cell;
      vertical-align: middle;

      .order-list li {
        list-style-type: none;
        line-height: 25px;
        font-weight: 400;
      }
      .order-list li span {
        color: #0084c2;
        font-weight: 500;
        padding-right: 10px;
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 575px) {
    padding: 15px 0;
  }
}

.error-code {
  color: $gray-900;
  font-size: 160px;
  text-align: center;
  line-height: 1;
  font-weight: 600;
  text-shadow: 10px 6px 8px rgba(117, 117, 117, 0.8);

  @media screen and (max-width: 991px) {
    font-size: 130px;
  }

  @media screen and (max-width: 767px) {
    font-size: 100px;
  }

  @media screen and (max-width: 575px) {
    font-size: 80px;
  }
}
