.postsignup-next {
  .spinner-border {
    vertical-align: inherit !important;
    border-width: 0.1em !important;
    margin-right: 1px !important;
  }
}
.grey-toggle {
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #a5a4a1;
  }
}
.btn-wrap {
  display: flex;
  float: right !important;
  justify-content: flex-end;
}
.cal-icon-align {
  .MuiInputAdornment-root {
    button {
      padding: 0px;
    }
  }
}
.slider-div {
  .slider {
    .MuiSlider-thumb {
      color: #2196f3;
    }
    .MuiSlider-rail {
      color: grey;
    }
    .MuiSlider-track {
      color: #2196f3;
    }
  }
  .MuiInput-inputMarginDense {
    height: 1.6rem !important;
  }
  .percentage-input {
    width: auto;
    max-width: 37px;
    height: 1.6rem;
    margin-top: -4px;
    border-bottom: 1px solid #ccc;
    .MuiInput-input {
      border-bottom: 0 !important;
    }
    &:before {
      border-bottom: 0px !important;
    }
  }
}

.tax-w-p {
  padding-left: 40px !important;
  padding-right: 52px !important;
}
.certify {
  margin-top: 30px;
  margin-bottom: 30px;
}

.phone {
  width: 100%;
}

.phone input:disabled {
  color: grey !important;
}

.phone:before {
  display: none;
}

.phone:after {
  display: none;
}

.tax-w-form {
  .jumbotron {
    margin-bottom: 20px !important;
    div {
      font-size: 12px;
      line-height: 23px;
      text-align: left;
      .m-8 {
        margin-bottom: 8px;
      }
      ol {
        padding-inline-start: 33px;
        margin-bottom: 0;
        li {
          margin-bottom: 4px;
        }
      }
    }
  }
  .disabledField {
    color: grey !important;
  }
}

.download-link-div {
  border: 1px solid #0084c2;
  padding: 3px 20px 4px 20px;
  font-family: 'Nunito Sans', sans-serif !important;
  border-radius: 15px;
  cursor: pointer;
  color: #0084c2 !important;
  text-align: center;
  a {
    cursor: pointer;
  }
  a:focus {
    outline: none;
  }
}
.download-link-div:focus-within {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  outline: none;
}

.btn-file {
  label {
    padding-left: 5px;
    display: block;
  }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1;
    cursor: pointer;
  }
  .inputfile:active + label {
    color: #fff;
    .filename {
      color: #fff;
      transition: 0.3s ease-out;
    }
  }
  .inputfile:focus + label,
  .inputfile.has-focus + label {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
a {
  text-decoration: none;
  cursor: default;
  &:active {
    color: #fff;
  }
  &:visited {
    color: inherit;
  }
}

.custom-control-label {
  cursor: pointer;
}
.post-signup-forms {
  .mailling-addres-wrap {
    padding: 0px;
    max-width: 100%;
    .mailing-heading {
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
      color: #525252;
      margin-bottom: 4px;
    }
  }
  .ssnInfoIcon {
    flex-direction: column;
    position: relative;
    svg.ssnInfoMargin {
      position: absolute;
      right: 0px;
      font-size: 15px;
      color: #ff9a14;
    }
    ~ .error-msg {
      margin-top: -16px;
    }
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .tax-w-form {
    .jumbotron {
      margin-bottom: 0px !important;
    }
  }
}

@media (max-width: 769px) {
  .mob-view {
    margin-bottom: 20px;
  }
  .tax-w-form {
    .custom-control {
      padding-top: 10px;
    }
    .btn-wrap {
      float: none;
      display: flex;
      justify-content: center;
      margin: 10px;
    }
  }
  .post-signup-forms {
    .mailling-addres-wrap {
      padding-bottom: 0px;
    }
  }
}
@media (min-width: 991px) {
  .download-link-div {
    border: 1px solid #0084c2;
    padding: 3px 10px 4px 10px;
    border-radius: 15px;
    color: #0084c2 !important;
    text-align: center;
  }
}
