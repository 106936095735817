@import '../../../../styles/global/variables';
.page-agreement-wrapper {
  justify-content: center;
  padding-top: 40px;

  img {
    margin-bottom: 32px;
  }

  .heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 56px;
  }

  .signup-btn-action-card {
    background: #fff;
    right: 0 !important;
    bottom: 0;
  }

  .text-label {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #525252;
    background: #FFF5E9;
  }

  .date-label {
    @extend .text-label;
    font-size: 11px;
    line-height: 140%;
    color: #A5A4A1;
  }

  .icon-calender-icon {
    padding-bottom: 6px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .name-title-error {
    position: absolute;
    bottom: -5px;
    left: 28px;
  }

  .default-cursor {
    cursor: default;
  }

  .checked-check-box {
    padding-inline-start: 0 !important;
  }

  .checked-check-box [type='checkbox'].filled-in:not(:checked)+span:not(.lever):after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #FF9A14;
    top: 0px;
    z-index: 0;
    border-radius: 2px;
  }

  .checked-check-box [type='checkbox'].filled-in:checked+span:not(.lever):after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $orange;
    background-color: $orange;
    z-index: 0;
    border-radius: 2px;
  }

  .agreement-head {
    margin-bottom: 8px;

    span {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      text-align: justify;
      letter-spacing: -0.3px;
      color: #4D4D4C;
    }
  }

  .agreement-sub-heading {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.3px;
    color: #4D4D4C;
    border-bottom: 1px solid #707070;
    padding-bottom: 24px;
  }

  .agree-para {
    p {
      text-align: justify;
    }
  }

  .agree-head {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 15px;
  }

  .agree-ul {
    list-style-type: none;

    li {
      margin-bottom: 10px;
      text-align: justify;
    }
  }

  .mt-32 {
    margin-top: 32px;
  }

  .name-title-label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #525252;
  }

  .lbl-by {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-top: -9px;
    margin-right: 12px;
  }

  .p-weight {
    padding-top: 24px;
    padding-bottom: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: justify;
    color: #4D4D4C;
  }

  .tax-w-form {
    .btn-wrap {
      float: right;
    }

    .margin-bot {
      margin-bottom: 60px;
    }

    .btn {
      margin-top: 17px !important;
    }
  }

  .certify p {
    margin-top: 20px;
  }

  .span-name {
    width: 50%;
    border-bottom: 1px solid #000;
  }

  hr {
    border-color: #707070;
  }

  input[type='text']:not(.browser-default) {
    height: 1rem !important;
    border-color: #000;
    padding-bottom: 6px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  .hr-m {
    margin-top: 4rem;
  }

  .orange-bg {
    background-color: #FFF5E9;
    padding: 20px;
    margin: 40px;
    margin-bottom: 0px;
  }

  .text-color {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 20px;
    height: 250px;
    background-color: #FFF5E9;
  }

  .tax-w-form .row {
    margin-bottom: 16px;
  }

  .mb-3a {
    margin-bottom: 40px;
  }

  .card-content h6 {
    margin-bottom: 25px;
  }

  .m-33 {
    margin-left: 33px !important;
  }

  .pl-51 {
    padding-left: 51px !important;
  }

  .label-by {
    padding-top: 18px !important;
    max-width: 30px;
  }

  .agreement-wrap-step {
    margin-top: -40px;

    .agreement-content {
      background-color: $papaya;
      padding: 20px 20px 0;
      margin-bottom: 32px;
    }

    .agreement-header {
      background-color: $papaya;
      padding: 20px !important;
    }
  }

  .agreement-check {
    border: 1px solid $orange;
    border-radius: 4px;
    padding: 24px;
    margin: 20px 24px 40px;

    @media (min-width: 769px) {
      padding: 16px;
      width: fit-content;
      margin: 20px 0 40px;
    }
  }

  .mt-20 {
    margin-top: 20px;
  }

  .agreement-form-group {
    padding: 0;

    @media (max-width:768px) {
      padding: 0px 24px;
    }
  }

  .btn-wrap {
    border-top: 1px solid #dfdfda;

    @media (min-width: 769px) {
      padding: 16px 0;
    }
  }
}

.loader-div {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (min-width:599px) {
  .padding-right-10 {
    padding-right: 10px;
  }

  .padding-left-10 {
    padding-left: 10px;
  }
}

@media (max-width: 768px) {
  .page-agreement-wrapper {
    .orange-bg {
      margin: 0px;
    }

    .margin-bottom-16 {
      margin-bottom: 16px;
    }

    .tax-w-form {
      .btn-wrap {
        float: none;
        display: flex;
        justify-content: center;
        margin: 10px;
      }
    }

    .agreement-wrap-step {
      padding: 0px;
    }
  }
}