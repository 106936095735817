@import '../../../../styles/color-theme-variable.scss';

.helpoutline-icon {
    position: absolute;
    top: 11px;
    right: 11px;
    font-size: 18px;
    color: #0084c2;
    cursor: pointer;

  .font-s {
    font-size: 18px;
  }
}

.mb-86 {
margin-bottom: 86px !important;
}
.step-5 {
  .pt-20 {
    padding-top: 20px;
  }
  .padding-bottom-20 {
    padding-bottom: 20px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .default-cursor {
    cursor: default;
  }
  .tax-checked-check-box {
    padding-inline-start: 0px !important;
  }
  .tax-checked-check-box
    [type='checkbox'].filled-in:not(:checked)
    + span:not(.lever):after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #858383;
    top: 0px;
    z-index: 0;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
  }
  .tax-checked-check-box
    [type='checkbox'].filled-in:checked
    + span:not(.lever):after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #0084c2;
    background-color: #0084c2;
    z-index: 0;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
  }
  .btn-wrap {
    float: right;
  }
  .input-field {
    label {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .expDate .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .expDate .MuiInput-underline::after,
  .expDate .MuiInput-underline::before {
    border-bottom: none;
  }
  .expDate .MuiFormHelperText-root.Mui-error {
    display: none;
  }
}

.mar-t-40 {
  margin-top: 20px;
}
.step-5 {
  .filename {
    color: #0084c2;
  }
  .expDate {
    .MuiInput-underline.Mui-disabled:before {
      border-bottom-style: none !important;
    }
    button {
      padding: 0;
      float: right;
      position: absolute;
      right: 0px;
    }
    .MuiTextField-root {
      width: 100%;
      margin-bottom: 0px;
    }
  }
  .mar-t-2 {
    margin-top: 20px !important;
  }
  .mar-t-4 {
    margin-top: 10%;
  }
.upload-orange {
  .btn-file {
    .file {
      color: #0084c2;
      padding-left: 12px;
      line-height: 24px;
      border: 0px;
    }
  }
}
  .btn-file {
    .file {
      color: #0084c2;
      padding-left: 12px;
      line-height: 24px;
      background: #FFF5E9;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .inputfile + label {
      white-space: nowrap;
      cursor: pointer;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 250px;
      border: none;
      font-family: 'Nunito Sans';
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      text-align: center;
      text-transform: uppercase;
      color: #333333;
    }
    .inputfile:active + label {
      color: #fff;
      .filename {
        color: #fff;
        transition: 0.3s ease-out;
      }
    }

    .inputfile:focus + label,
    .inputfile.has-focus + label {
      outline: none;
      outline: none;
    }
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.license-step {
  .DatePickerComponent {
    .MuiInputAdornment-positionEnd {
      margin-left: 0px;

      button {
        position: absolute;
        right: -3px;
      }
    }
  }
}
.DatePickerComponent {
  width: 100% !important;
  div {
    div {
      button {
        color: #0084c2;
      }
    }
  }
}
.licenseNumber,
.businessLicenseNumber,
.aggregateLimit {
  width: 100% !important;
}

.info-tooltip {
  color: #0084c2;
}

//MUI - underline for limit input
.aggregateLimit .MuiInput-underline:hover:not(.Mui-disabled):before,
.aggregateLimit .MuiInput-underline:hover:not(.Mui-disabled):after,
.aggregateLimit .MuiInput-underline:after,
.aggregateLimit .MuiInput-underline:before {
  border-bottom: none;
}
@media (max-width:600px) {
 .step-5 {
  .doc-label-wrap {
    .document-btn-file {
      min-width:unset;
      .mob-dropzone {
        background-color: transparent;
        border: 0;
        span {
            font-family: $Nunito-font-family;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            color: $fc-Orange;
            cursor: pointer;
        }
      }
    }
  }
 } 
}
@media (min-width: 768px) {
  .step-5-container {
    input[type='text']:not(.browser-default) {
      width: 93%;
    }
    .mat-icon {
      right: 9px;
    }
    .select-wrapper .select-dropdown {
      width: 93%;
    }
  }
}
@media (max-width: 769px) {
  .mat-icon {
    right: 0;
  }
  .expDate button {
    right: -16px;
  }
  .mar-t-40 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    label {
      margin-bottom: 10px;
    }
  }
  .step-5 {
    .btn-wrap {
      float: none;
      display: flex;
      justify-content: center;
      margin: 10px;
    }
    .btn-file {
      .inputfile + label {
        max-width: 100%;
        white-space: nowrap;
        cursor: pointer;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        width: 100%;
        margin-bottom: 0px !important;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .step-5 {
    .btn-file {
      .inputfile + label {
        max-width: 80%;
        white-space: nowrap;
        cursor: pointer;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media (min-width: 991px) and (max-width: 1024px) {
  .step-5 {
    .btn-file {
      .inputfile + label {
        max-width: 80%;
        white-space: nowrap;
        cursor: pointer;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }
    }
  }
}
