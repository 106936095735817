.mat-icon {
  position: relative;
  top: -60px;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;

  .MuiIconButton-root {
    &:hover {
      background-color: transparent;
    }
  }
  svg {
    font-size: 14px;
  }

  .font-s {
    font-size: 18px;
  }
  button {
    padding:0px
  }
}
.license-height {
  min-height: 430px;
  @media (max-width:768px) {
    min-height: inherit;
  }
}
.license-btn-position {
  @media (min-width:769px) {
    position: absolute;
    width: 100%;
    bottom: 0;
    top: unset !important
  }
  @media (max-width:768px) {
    position: fixed;
  }
}
.question-mark {
  color: #ff9900;
}
.check-box {
  color: #009e0f;
}
.step-4 {
  .padding-bottom-20 {
    padding-bottom: 20px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .default-cursor {
    cursor: default;
  }
  .tax-checked-check-box {
    padding-inline-start: 0px !important;
  }
  .tax-checked-check-box
    [type='checkbox'].filled-in:not(:checked)
    + span:not(.lever):after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #858383;
    top: 0px;
    z-index: 0;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
  }
  .tax-checked-check-box
    [type='checkbox'].filled-in:checked
    + span:not(.lever):after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #0084c2;
    background-color: #0084c2;
    z-index: 0;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
  }
  .btn-wrap {
    float: right;
  }
  .input-field {
    label {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .expDate {
    button {
      padding: 0;
      float: right;
      position: absolute;
      top: 10%;
    }
  }

  .expDate .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .expDate .MuiInput-underline::after,
  .expDate .MuiInput-underline::before {
    border-bottom: none;
  }
  .expDate .MuiFormHelperText-root.Mui-error {
    display: none;
  }
  
}
.fieldWithIcon{
  position: relative;
  .mat-icon{
    position: absolute;
    top: 0.5rem;
    right: 10px;
  }
}
.writeUnderAgencyText {
  display: flex;
  .tax-checked-check-box {
    display: inline-flex;
    align-items: center;
    input{
      margin-right: 8px;
    }
  }
  .tooltipIcon{
    font-size: 15px;
    margin-left: 8px;
  }
}
.writeUnderAgency {
  .license-tooltip {
    position: absolute;
    margin-left: 11px;
    width: 16px;
    height: 16px;
  }
}

.mar-t-40 {
  margin-top: 20px;
}
.step-4 {
  .filename {
    color: #0084c2;
  }
  .expDate {
    div {
      div {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }
  .mar-t-2 {
    margin-top: 20px !important;
  }
  .mar-t-4 {
    margin-top: 10%;
  }
.license-height {
  .btn-file {
    .file {
      color: #0084c2 !important;
      padding-left: 12px;
      line-height: 24px;
      background-color: #ffffff !important;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .inputfile + label {
      max-width: 60%;
      white-space: nowrap;
      cursor: pointer;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .inputfile:active + label {
      color: #fff;
      .filename {
        color: #fff;
        transition: 0.3s ease-out;
      }
    }

    .inputfile:focus + label,
    .inputfile.has-focus + label {
      outline: none;
      outline: none;
    }
  }
}
}
.licenseNPNdrop {
  z-index: 999 !important;
  color: white;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.DatePickerComponent {
  width: 100% !important;
  div {
    div {
      button {
        color: #0084c2;
      }
    }
  }
}
.licenseNumber,
.businessLicenseNumber,
.aggregateLimit {
  width: 100% !important;
}

.info-tooltip {
  color: #0084c2;
}

//MUI - underline for limit input
.aggregateLimit .MuiInput-underline:hover:not(.Mui-disabled):before,
.aggregateLimit .MuiInput-underline:hover:not(.Mui-disabled):after,
.aggregateLimit .MuiInput-underline:after,
.aggregateLimit .MuiInput-underline:before {
  border-bottom: none;
  border-bottom-style: none !important;
}
.agencyLicenceNo {
  padding-top: 15px !important;
}
@media (min-width: 768px) {
  .step-4-container {
    input[type='text']:not(.browser-default) {
      width: 93%;
    }
    .mat-icon {
      right: 9px;
    }
    .select-wrapper .select-dropdown {
      width: 93%;
      @-moz-document url-prefix() {
        width: 100%;
      }
    }
  }
}
@media (max-width: 769px) {
  .mat-icon {
    right: 0;
  }
  .expDate button {
    right: -16px;
  }
  .mar-t-40 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    label {
      margin-bottom: 10px;
    }
  }
  .step-4 {
    .btn-wrap {
      float: none;
      display: flex;
      justify-content: center;
      margin: 10px;
    }
    .btn-file {
      .inputfile + label {
        max-width: 100%;
        white-space: nowrap;
        cursor: pointer;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        width: 100%;
        margin-bottom: 0px !important;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .step-4 {
    .btn-file {
      .inputfile + label {
        max-width: 80%;
        white-space: nowrap;
        cursor: pointer;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media (min-width: 991px) and (max-width: 1024px) {
  .step-4 {
    .btn-file {
      .inputfile + label {
        max-width: 80%;
        white-space: nowrap;
        cursor: pointer;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }
    }
  }
}
