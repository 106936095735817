@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .app-main-content-wrapper {
    width: 100%;

    overflow-y: scroll;
    .app-footer {
      margin-top: 30px;
    }
  }
  .app-main-content {
    width: 100%;
    padding-bottom: 40px;
    .MuiDialog-paperWidthMd {
      width: 100%;

      overflow-y: auto;
      border: 1px solid #f00;
    }
    .ie-cls {
      word-break: normal !important;
      word-wrap: break-word;
      -ms-word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }

  // for agent details component policy career graph
  .colorPletBox {
    padding-left: 0px !important;
  }

  .agent-details-card,
  .settingWrapper,
  .AgentCommissionWrapper,
  .team-member-table,
  .AgentPolicyTableWrapper,
  .UpcommingRenewalsWrapper,
  .incomplete-table {
    .MuiToolbar-regular {
      padding-top: 15px;
    }
  }
  .my-account-wrapper,
  .myCommissionWrapper,
  .carriersWrapper,
  .AgentCommissionWrapper {
    .MuiToolbar-regular {
      padding-top: 15px;
    }
  }
  .AgencyProfileWrapper {
    .MuiDialog-paperWidthMd {
      width: 100%;

      overflow-y: auto;
    }
  }
  .BankInformationWrapper,
  .w9InformationWrapper,
  .agent-details-card,
  .LicenseWrapper {
    .MuiDialog-paperWidthSm {
      width: 100%;
    }
  }

  .CarrierOnboardWrapper {
    .MuiDialog-paperWidthMd {
      width: 100%;

      overflow-y: auto;
    }
  }

  .CarrierOnboardUpdateInfoWrapper {
    .MuiDialog-paperWidthMd {
      width: 100%;

      overflow-y: auto;
    }
  }

  .CarrierAddProductWrapper {
    .MuiDialog-paperWidthMd {
      width: 100%;
      overflow-y: auto;
    }
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .app-footer {
    position: relative;
    bottom: 0;
    width: 100%;
    z-index: 50;
  }
}
