@import '../../styles/color-theme-variable.scss';
$line-height-140: 140%;

.mobile-to-desk-wrap,
.switch-notification {
  display: none;
}

@media (max-width: 600px) {
  .mobile-to-desk-wrap {
    display: block;
    margin: 0 auto;
    .first-heading {
      font-family: $Nunito-font-family;
      font-weight: 700;
      font-size: 20px;
      line-height: $line-height-140;
      color: $color-text-grey;
      margin-bottom: 16px;
      margin-top: 32px;
    }

    .m-para {
      font-family: $Nunito-font-family;
      font-weight: 400;
      font-size: 12px;
      line-height: $line-height-140;
      color: $color-text-grey;
      margin-bottom: 32px;
    }

    .label-email {
      font-family: $Nunito-font-family;
      font-weight: 700;
      font-size: 12px;
      line-height: 120%;
      color: $color-text-grey;
      margin-bottom: 16px;
    }

    .m-orange-button {
      max-width: 287px;
      width: 100%;
      height: 40px;
      background: $fc-Orange;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      font-family: $Nunito-font-family;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 32px;
      &:hover {
        background: $fc-Orange;
      }
    }
    .mt-40 {
      margin-top: 40px;
    }
    .mt-32 {
      margin-top: 32px;
    }
    .label-orange {
      font-family: $Nunito-font-family;
      font-weight: 700;
      font-size: 14px;
      line-height: $line-height-140;
      color: $fc-Orange;
      text-align: center;
      cursor: pointer;
      margin-bottom: 32px;
      margin-top: 32px;
    }

    .lbl-cancel {
      font-family: 'Nunito Sans', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      color: #ff9a14;
      cursor: pointer;
      border: 0px;
      background: transparent;
      position: absolute;
      right: 0;
      bottom: 11px;
    }
    .text-field-label {
      align-items: center;
      height: 2.6rem;
      .text-elipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .text-field {
      input {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
      }
    }
    .edit-btn {
      .edit-icon {
        width: 16px;
        height: 16px;
      }
    }
    .mobile-loader {
      width: 120px;
      height: 120px;
    }
    .mob-position {
      position: fixed;
      left: 0;
      bottom: 0;
    }
    .mob-white-back-button {
      width: 40px !important;
      height: 40px;
      border: 1px solid #f2f2f2;
      border-radius: 50%;
      color: #333333;
      padding: 0px 20px;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
      background: #fff url(../../assets/images/back-btn-icon.svg) no-repeat
        center center;
    }
    .error-position {
      color: #f44336;
      font-size: 14px;
      position: absolute;
      bottom: -24px;
    }
  }
  .switch-notification {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 16px 0px 16px;
    margin-top: 20px;

    .orange-label {
      color: #FF8125;
      text-align: center;
      font-family: 'Nunito Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      text-decoration-line: underline;
      margin-bottom: 0px;
    }
    .close-icon {
      color: #ffffff;
      width: 16px;
      height: 16px;
      margin-left: 11.57px;
    }
  }
}
