/*Theme Dark Blue Styles*/

@import '../global/variables';
$app-primary-purplish-blue-theme: #10069f !default;
$app-header-purplish-blue-theme: white !default;
$secondary-purplish-blue-theme: #ff6e40;
$text-color: #101820;
$light-purple:#dad7fb;
$sidebar-purplish-blue-theme: $white  !default;
$sidebar-text-purplish-blue-theme: #6b7a93 !default;
$sidebar-darken-blue: darken($sidebar-purplish-blue-theme, 3%);
$sidebar-bright-button-color: #f4f4f7;
$sidebar-grey-button-color: #6b7a93 !default;
$sidebar-bg-darken-highlight-blue: $app-primary-purplish-blue-theme;
$theme: $app-primary-purplish-blue-theme;
// Links
$link-hover-purplish-blue-theme: $app-primary-purplish-blue-theme;
$blueColor-theme: $app-primary-purplish-blue-theme;
$app-header-light-sky-blue: #f0f8ff;
$size: 100px;
$duration: 1.5s;
$timing: ease;

//- Bootstrap file Style
.purplish-blue {
  .orange-text {
    color: $app-primary-purplish-blue-theme;
  }
  .hover-text-label {
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $app-primary-purplish-blue-theme;
    }
  }

  .poc-email-wrap {
    >div {
      &::after {
        border-bottom: 2px solid $app-primary-purplish-blue-theme;
      }
    }
  }

  .po .post-signup-agency {
    .expDate {
      svg {
        color: $app-primary-purplish-blue-theme;
      }
    }
  }

  .dashboard-wrapper {
    .calender-wrap {
      svg {
        color: $app-primary-purplish-blue-theme;
      }
    }
  }

  a,
  .card-link,
  .jr-link,
  .jr-link.text-primary {
    color: $app-primary-purplish-blue-theme;

    &:focus,
    &:hover {
      color: darken($app-primary-purplish-blue-theme, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-purplish-blue-theme  !important;
  }

  & .error-text {
    color: #f44336;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
  }

  & .page-link {
    color: $app-primary-purplish-blue-theme;

    &:focus,
    &:hover {
      color: $app-primary-purplish-blue-theme;
    }
  }

  & .page-heading .breadcrumb-item.active {
    color: $app-primary-purplish-blue-theme;
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-purplish-blue-theme  !important;
    color: $white  !important;
    font-weight: 400 !important;
    box-shadow: none;
  }

  & button.bg-primary {

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-purplish-blue-theme,
          10%) !important;
      border-color: darken($app-primary-purplish-blue-theme, 10%) !important;
      color: $white  !important;
    }
  }

  & .btn-primary {
    background-color: $app-primary-purplish-blue-theme  !important;
    border-color: $app-primary-purplish-blue-theme  !important;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-purplish-blue-theme, 5%) !important;
      border-color: darken($app-primary-purplish-blue-theme, 5%) !important;
    }
  }

  //-Base-scss
  .right-arrow {
    color: $app-primary-purplish-blue-theme;

    &:after {
      color: $app-primary-purplish-blue-theme;
    }
  }

  //apoinment
  .redirect-appointment-request {
    p {
      color: $app-primary-purplish-blue-theme;
    }
  }

  .appointment-requests-count {
    border: 2px solid #abd4f9;
  }

  .appointment-requests-count-active {
    border-bottom: 10px solid $app-primary-purplish-blue-theme;
  }

  .title-content {
    label {
      color: $app-primary-purplish-blue-theme;
    }
  }

  .appointment-request-products {
    .product-box {
      .list-products {
        li {
          border: 1px solid $app-primary-purplish-blue-theme;

          &:first-child {
            border-top: 1px solid $app-primary-purplish-blue-theme;
          }

          div {
            .name {
              color: $app-primary-purplish-blue-theme;
            }
          }

          .radio-button {
            color: $app-primary-purplish-blue-theme;
          }
        }
      }
    }
  }

  .appointment-request-information {
    .request-info {
      .circle-shape {
        color: $app-primary-purplish-blue-theme;
      }
    }
  }

  .maps-box {
    &>div svg {
      margin-right: 5px;
      color: $app-primary-purplish-blue-theme;
    }
  }

  .appointment-request-process {
    .summary-border {
      border: 1px solid $app-primary-purplish-blue-theme;

      .MuiAccordionSummary-expandIcon {
        color: $app-primary-purplish-blue-theme;
      }
    }

    .accordion-details-border {
      @extend .summary-border;
    }
  }

  .appointment-requests-action {
    align-items: center;
    justify-content: center;

    button:last-child {
      border: 1px solid $app-primary-purplish-blue-theme;
      box-shadow: none;
      color: $app-primary-purplish-blue-theme;
    }
  }

  .appointment-requests {
    .completed-check-icon {
      background-image: url(../../assets/images/appointment-requests/check-purplish-blue.svg);
      background-size: 22px;
      background-position: center;
      background-repeat: no-repeat;
      padding: 20px;
    }
  }

  .jr-link.text-secondary {
    color: $secondary-purplish-blue-theme;

    &:focus,
    &:hover {
      color: darken($secondary-purplish-blue-theme, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-purplish-blue-theme  !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-purplish-blue-theme  !important;
    color: $link-hover-purplish-blue-theme  !important;
  }

  & .btn-secondary {
    background-color: $secondary-purplish-blue-theme;
    border-color: $secondary-purplish-blue-theme;
    color: $link-hover-purplish-blue-theme;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-purplish-blue-theme, 5%) !important;
      border-color: darken($secondary-purplish-blue-theme, 5%) !important;
      color: $link-hover-purplish-blue-theme  !important;
    }
  }

  .purplish-blue-bg {
    background-color: $white  !important;
  }

  .app-logo1 {
    width: 50%;

    img {
      height: 100%;
      width: 100%;
      max-width: 160px;
    }
  }

  .side-nav {
    background-color: $sidebar-purplish-blue-theme  !important;
    color: $sidebar-text-purplish-blue-theme  !important;
    box-shadow: none !important;

    & .user-profile {
      background-color: $sidebar-darken-blue;
      box-shadow: none;
      position: relative;
      z-index: 2;
      border-bottom: 2px solid #2a7de1;
    }

    & .customizer {
      border-bottom: 0 none;
    }

    & .user-detail {
      h4 {
        color: $app-primary-purplish-blue-theme;
      }

      & .user-name {
        color: $link-hover-purplish-blue-theme;
      }
    }

    .nav-menu {
      .MuiButton-root {
        color: $sidebar-text-purplish-blue-theme;
      }
    }

    .user-avatar {
      border: 2px solid $app-primary-purplish-blue-theme;
      border-radius: 100%;

      svg {
        color: $app-primary-purplish-blue-theme;
      }
    }

    li {
      a {
        color: $sidebar-text-navy-blue-theme;
      }

      &.open {
        color: $app-primary-purplish-blue-theme;
      }
    }

    ul.nav-menu li a:focus,
    ul.nav-menu li button:focus {
      background-color: $sidebar-darken-blue;
      color: $app-primary-purplish-blue-theme;
    }
  }

  ul.nav-menu {
    & .nav-header {
      color: $sidebar-text-purplish-blue-theme;
    }
  }

  ul.nav-menu>li.open>button,
  ul.nav-menu>li.open>button:hover,
  ul.nav-menu>li.open>button:focus,
  ul.nav-menu>li.open>a,
  ul.nav-menu>li.open>a:hover,
  ul.nav-menu>li.open>a:focus {
    background-color: $sidebar-purplish-blue-theme;
    color: $sidebar-grey-button-color;
  }

  ul.nav-menu>li>a:hover {
    background-color: $sidebar-purplish-blue-theme;
    color: $link-hover-purplish-blue-theme;
  }

  ul.nav-menu>li>button:hover {
    background-color: $sidebar-purplish-blue-theme;
    color: $link-hover-purplish-blue-theme;
  }

  ul.nav-menu li.menu.open>button:before {
    color: $app-primary-purplish-blue-theme;
  }

  ul.nav-menu li.menu.open>button:hover:before {
    color: $app-primary-purplish-blue-theme;
  }

  ul.nav-menu li.menu>button:hover:before {
    color: $link-hover-purplish-blue-theme;
  }

  ul.nav-menu li ul {
    background-color: $sidebar-purplish-blue-theme;
  }

  ul.nav-menu li.menu>button:before,
  ul.nav-menu li.menu>a:before {
    color: $sidebar-text-purplish-blue-theme;
  }

  ul.nav-menu li.menu.open>a:before,
  ul.nav-menu li.menu>a:focus:before,
  ul.nav-menu li.menu li>a:hover:before {
    color: $link-hover-purplish-blue-theme;
  }

  ul.nav-menu li.menu .sub-menu li>a:before {
    color: $sidebar-text-purplish-blue-theme;
  }

  ul.nav-menu li.menu.open .sub-menu li>a.active {
    background-color: #d5e0f3;
    color: $app-primary-purplish-blue-theme;
    border-radius: 7px;
    font-weight: bold;
  }

  ul.nav-menu li.menu .sub-menu li>a:hover,
  ul.nav-menu li.menu .sub-menu li>a:focus,
  ul.nav-menu li.menu.open .sub-menu li>a.active:before,
  ul.nav-menu li.menu .sub-menu li>a:focus:before,
  ul.nav-menu li.menu .sub-menu li>a:hover:before {
    background-color: #d5e0f3;
    color: $link-hover-purplish-blue-theme;
    border-radius: 7px;
  }

  /*Header top Navbar Styles*/
  .navbar-nav {
    & li {

      &:hover>a,
      &:focus>a,
      &:hover>.nav-link,
      &:focus>.nav-link {
        color: $app-primary-purplish-blue-theme;
      }

      & a,
      & .nav-link {
        color: $sidebar-text-color;
      }

      & a:hover,
      & a:focus,
      & .nav-link:hover,
      & .nav-link:focus {
        color: $app-primary-purplish-blue-theme;
      }
    }

    & li.nav-item>a,
    & li.nav-item>.nav-link {
      color: $white;
    }

    & li.nav-item:hover>a,
    & li.nav-item:focus>a,
    & li.nav-item>a:hover,
    & li.nav-item>a:focus,
    & li.nav-item.active>a,
    & li.nav-item:hover>.nav-link,
    & li.nav-item:focus>.nav-link,
    & li.nav-item>.nav-link:hover,
    & li.nav-item>.nav-link:focus,
    & li.nav-item.active>.nav-link {
      color: $secondary-purplish-blue-theme;
    }

    ul.sub-menu {

      & li a.active,
      & li.active>a,
      & li .nav-link.active,
      & li.active>.nav-link {
        color: $app-primary-purplish-blue-theme;
      }
    }

    & .nav-arrow {

      &>a:before,
      &>.nav-link:before {
        color: $sidebar-text-color;
      }

      & a:hover,
      & a:focus,
      & .nav-link:hover,
      & .nav-link:focus {
        &:before {
          color: $app-primary-purplish-blue-theme;
        }
      }

      &:hover>a:before,
      &:focus>a:before,
      &.active>a:before,
      &:hover>.nav-link:before,
      &:focus>.nav-link:before,
      &.active>.nav-link:before {
        color: $app-primary-purplish-blue-theme;
      }
    }
  }

  .app-top-nav {
    & .navbar-nav {

      & li.nav-item>a,
      & li.nav-item>.nav-link {
        color: $white;
      }

      & li.nav-item:hover>a,
      & li.nav-item:focus>a,
      & li.nav-item>a:hover,
      & li.nav-item>a:focus,
      & li.nav-item.active>a,
      & li.nav-item:hover>.nav-link,
      & li.nav-item:focus>.nav-link,
      & li.nav-item>.nav-link:hover,
      & li.nav-item>.nav-link:focus,
      & li.nav-item.active>.nav-link {
        color: $secondary-purplish-blue-theme;
      }
    }
  }

  .app-header-horizontal {
    & .app-main-header-top {
      @include box-shadow(none !important);
    }
  }

  .header-notifications .app-notification {
    & .jr-list-link {
      @include hover-focus-active {
        color: $app-primary-purplish-blue-theme;
        border-color: $app-primary-purplish-blue-theme;
      }
    }
  }

  //_app-module.scss
  .module-nav {
    & li a {
      &.active {
        border-color: $app-primary-purplish-blue-theme;
      }
    }
  }

  //_calendar.scss
  .rbc-event {
    background-color: $app-primary-purplish-blue-theme;
  }

  .rbc-event.rbc-selected {
    background-color: darken($app-primary-purplish-blue-theme, 10%);
  }

  .rbc-slot-selection {
    background-color: rgba($app-primary-purplish-blue-theme, 0.7);
  }

  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    background-color: rgba($app-primary-purplish-blue-theme, 0.9);
    border-color: $app-primary-purplish-blue-theme;
  }

  .rbc-toolbar button:active:hover,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button:active:focus,
  .rbc-toolbar button.rbc-active:focus {
    background-color: rgba($app-primary-purplish-blue-theme, 0.9);
    border-color: $app-primary-purplish-blue-theme;
  }

  .rbc-toolbar button:focus {
    background-color: rgba($app-primary-purplish-blue-theme, 0.9);
    border-color: $app-primary-purplish-blue-theme;
  }

  .rbc-toolbar button:hover {
    background-color: rgba($app-primary-purplish-blue-theme, 0.9);
    border-color: $app-primary-purplish-blue-theme;
  }

  //_chat.scss
  .chat-sidenav-title {
    color: $app-primary-purplish-blue-theme;
  }

  .chat-user-item {

    &.active,
    &:hover {
      background-color: lighten($app-primary-purplish-blue-theme, 45%);
    }
  }

  //_dashboard.scss
  .contact-list {
    & i {
      color: $app-primary-purplish-blue-theme;
    }
  }

  //The link which when clicked opens the collapsable ChatUserList
  .Collapsible__trigger {
    background: $app-primary-purplish-blue-theme;
  }

  .categories-list {

    & li a:focus,
    & li a:hover,
    & li.active a {
      color: $app-primary-purplish-blue-theme;
    }
  }

  //_login.scss
  .login-content .form-control {
    &:focus {
      border-color: $app-primary-purplish-blue-theme;
    }
  }

  //_portfolio.scss
  .filter-with-bg-color ul li {
    .jr-link {
      border-color: $app-primary-purplish-blue-theme;

      &:hover,
      &:focus,
      &.active {
        background-color: $app-primary-purplish-blue-theme;
      }
    }
  }

  //_card.scss
  .profile-intro {
    & .icon {
      color: $app-primary-purplish-blue-theme;
    }
  }

  .social-link {

    & li.active a,
    & li a:hover,
    & li a:focus {
      color: $secondary-purplish-blue-theme;
    }
  }

  //_tables.scss
  .actions {
    color: $secondary-purplish-blue-theme;
  }

  .table-hover tbody tr:hover {
    background-color: rgba($app-primary-purplish-blue-theme, 0.075);
  }

  //Border Color
  .border-primary {
    border-color: $app-primary-purplish-blue-theme  !important;
  }

  // login page content
  .app-logo-content {
    background-color: $app-primary-purplish-blue-theme;
  }

  .app-social-block {
    & .social-link button {
      border: solid 1px $app-primary-purplish-blue-theme;
      color: $app-primary-purplish-blue-theme;

      &:hover,
      &:focus {
        color: $white;
        background-color: $app-primary-purplish-blue-theme;
      }
    }
  }

  /*Button Group Styles*/
  .actions .btn-group,
  .btn-group-vertical {
    >.jr-btn {
      &.active {
        background-color: $app-primary-purplish-blue-theme;
        border-color: $app-primary-purplish-blue-theme;
      }
    }

    >.jr-flat-btn {
      background-color: transparent;
      border-color: transparent;

      &.active {
        background-color: transparent;
        color: $app-primary-purplish-blue-theme;

        &:hover,
        &:focus {
          background-color: rgba($app-primary-purplish-blue-theme, 0.12);
          color: $app-primary-purplish-blue-theme;
        }
      }
    }
  }

  /*Classic Dashboard Styles*/
  .jr-fillchart-btn-close,
  .jr-onchart .jr-badge-up,
  .jr-task-list-item:hover .jr-text-hover {
    color: $app-primary-purplish-blue-theme;
  }

  .jr-entry-title:before {
    background-color: $app-primary-purplish-blue-theme;
  }

  .jr-card-ticketlist {

    & .jr-task-list-item:hover .jr-task-item-title,
    .jr-link {
      color: $app-primary-purplish-blue-theme;
    }
  }

  .slick-dots li.slick-active button:before {
    border-color: $app-primary-purplish-blue-theme;
  }

  //Nav Styles
  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: $white  !important;
    background-color: $app-primary-purplish-blue-theme;
  }

  // Gradient Color Class
  .bg-gradient-primary {
    @include gradient-directional($app-primary-purplish-blue-theme,
      lighten($app-primary-purplish-blue-theme, 16%),
      0deg);
  }

  .bg-gradient-primary-x {
    @include gradient-x(darken($app-primary-purplish-blue-theme, 10%),
      lighten($app-primary-purplish-blue-theme, 16%),
      70%,
      100%);
  }

  //Profile style
  .jr-profile-banner {
    background-color: $app-primary-purplish-blue-theme;
    color: $white;

    & .jr-link {
      color: $white;

      &:hover,
      &:focus {
        color: $secondary-purplish-blue-theme;
      }
    }
  }

  .dropdown-item {

    &.active,
    &:active {
      background-color: $app-primary-purplish-blue-theme;
      color: $white;
    }

    &[class*='text-'] {

      &.active,
      &:active {
        background-color: transparent;
      }
    }
  }

  //common color theme style
  .MuiInputBase-input {
    color: #101820;
  }

  .MuiFormLabel-root.Mui-error {
    color: #f44336 !important;
  }

  .MuiPickersDay-daySelected {
    p {
      color: #fff;
    }
  }

  //loginpage-logo
  .logo-wrap {
    padding-left: 60px;
    padding-top: 60px;

    .logo-img {
      max-width: 206px;
    }
  }

  .green-logo-wrap {
    padding-left: 60px;
    padding-top: 0px;

    .AS-logo {
      width: 269px;
    }
  }

  .jr-menu-icon .menu-icon {
    background-color: $app-primary-purplish-blue-theme  !important;
  }

  .jr-menu-icon .menu-icon:after,
  .jr-menu-icon .menu-icon:before {
    width: 100% !important;
  }

  .tabHeader {
    box-shadow: none !important;
  }

  .tabs {
    background: #f3f5f6;
  }

  .app-main-content {
    background: #f3f5f6 !important;
  }

  .app-header {
    header {
      border-bottom: 2px solid #2a7de1 !important;
      height: 71px !important;
    }
  }

  .dm-width {
    width: 32px;
    height: 17px;
  }

  label,
  .documentlbl,
  td,
  th {
    color: $text-color  !important;
  }

  .profile-details,
  .endoList {

    p,
    li {
      color: $text-color  !important;
    }
  }

  .headingLbl {
    span {
      color: $text-color  !important;
    }
  }

  .login-heading {
    color: $app-primary-purplish-blue-theme  !important;
    font-family: 'Gilroy-Bold' !important;
  }

  .wirefreame-bg {
    background-image: url('../../assets/images/doma/Wire-Frame-Pattern.svg');
    background-position: top;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 1060px 700px;
  }

  .wirefreame-bg-green {
    background-image: url('../../assets/images/Wire-Frame-Pattern-2.svg');
    background-position: top;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 1060px 700px;
    margin-top: 10px;
  }

  .button-login {
    background-color: $app-primary-purplish-blue-theme;

    &:hover {
      background-color: $app-primary-purplish-blue-theme;
    }
  }

  .postsign-btn {
    padding: 8px 22px;
    letter-spacing: 0.5px;
    font-weight: 400;
    border-radius: 20px;
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
    width: 150px;
    height: 40px;
    border: 0px;
    color: #fff;
    text-transform: uppercase;
  }

  .postsignup-back {
    background-color: #fff;
    border: 1px solid $app-primary-purplish-blue-theme;
    color: $app-primary-purplish-blue-theme;
    @extend .postsign-btn;
  }

  .postsignup-next {
    background-color: $orange-bold;
    @extend .postsign-btn;

    @media (max-width: 768px) {
      width: 120px;
      height: 40px;
    }
  }

  // signup-process scss
  .process-main-header {
    background-color: $white;
  }

  .update-password-button {
    background: $app-primary-purplish-blue-theme;
    border: 1px solid $app-primary-purplish-blue-theme;
  }

  .updateNewPasswordWrapper {
    .headColor {
      color: $app-primary-purplish-blue-theme;
    }
  }

  .signout-blue {
    border: 1px solid $app-primary-purplish-blue-theme;
    color: $app-primary-purplish-blue-theme;
  }

  .stamp {
    width: 37px;
    height: 40px;
    position: absolute;
    top: 12px;
  }

  .dashboard-wrapper {
    .headingCarrier {
      color: $app-primary-purplish-blue-theme  !important;
    }
  }

  .quoteTable,
  .UpcommingRenewalsWrapper {
    .MuiIconButton-root.Mui-disabled {
      color: $app-primary-purplish-blue-theme;
    }

    th {
      span {
        color: $app-primary-purplish-blue-theme;
      }
    }

    .quotes-label {
      color: $app-primary-purplish-blue-theme  !important;
    }
  }

  .white-button {
    border: 1px solid $app-primary-purplish-blue-theme;
    font-weight: bold;

    svg {
      color: $app-primary-purplish-blue-theme  !important;
    }
  }

  .quoteOption {
    border: 1px solid $app-primary-purplish-blue-theme;
    box-shadow: 0px 0px 10px #dae9f4;

    .product-heading {
      color: $text-color;
    }

    &:hover {
      border: 1px solid $app-primary-purplish-blue-theme;
    }

    .Mui-checked {
      color: $app-primary-purplish-blue-theme  !important;
    }
  }

  .tabHeader {
    .quote-connector-tab {
      .MuiTab-wrapper {
        word-spacing: normal;
      }
    }

    .trangle-icon {
      display: none;
    }

    .Mui-selected {
      @extend .text-blue;
    }

    .MuiButtonBase-root {
      color: #101820;
    }
  }

  .background {
    background-color: $app-primary-purplish-blue-theme;
  }

  .editfabicon {
    @extend .background;
  }

  .infoChip {
    height: auto;
    border-color: $app-primary-purplish-blue-theme;
  }

  .disableExecuteBtn {
    border: 1px solid $app-primary-purplish-blue-theme;

    &:disabled {
      color: $app-primary-purplish-blue-theme;
    }
  }

  .bluecard {
    @extend .background;
    padding: 15px 0px;
  }

  .btn-file,
  .document-btn-file {
    .inputfile+label {
      @extend .text-blue;
      border: 1px solid $app-primary-purplish-blue-theme;
    }
  }

  .product-list-wrap {
    .document-btn-file {
      border: 1px solid $app-primary-purplish-blue-theme;

      .file {
        color: $app-primary-purplish-blue-theme  !important;
      }
    }
  }

  .bg-border {
    background: $app-primary-purplish-blue-theme;
    border: 1px solid $app-primary-purplish-blue-theme;
  }
  .post-signup-wrapper {
    .MuiPickersDay-daySelected,
    .MuiPickersToolbar-toolbar {
      background-color: $app-primary-purplish-blue-theme;
    }
        .recover-pwd-wrap {
          .recover-password-form {
            .send-request-button {
              background-color: $app-primary-purplish-blue-theme;
            }
          }
        }
  }

  .MuiPickersDay-daySelected,
  .MuiPickersToolbar-toolbar {
    @extend .background;
  }

  .MuiTypography-colorPrimary {
    @extend .text-blue;
  }

  .MuiPickersYear-root {
    &:focus {
      @extend .text-blue;
    }
  }

  .datePicker {
    button {
      color: $app-primary-purplish-blue-theme;
    }
  }

  .MuiChip-outlinedPrimary {
    border: 1px solid $app-primary-purplish-blue-theme  !important;
    @extend .text-blue;
  }
  .theme-color {
    &:hover {
      border: 1px solid $app-primary-purplish-blue-theme;
    }
  }

  .complienceWraper {
    .complienceCardDiv {
      p {
        @extend .text-blue;
      }
    }
  }

  .searcgLbl {
    background-color: $app-primary-purplish-blue-theme;
  }

  .font {
    font-size: 21px;
  }

  .searchfieldForm {
    .clrbtn {
      color: $app-primary-purplish-blue-theme;
      border: 1px solid $app-primary-purplish-blue-theme;
      background-color: #fff;
      box-shadow: none;
      font-size: 12px;

      &:hover {
        background-color: $white;
      }
    }

    .searchbtn {
      @extend .searcgLbl;
      border-radius: 4px;
      font-weight: normal;

      &:hover {
        @extend .searcgLbl;
        border-radius: 4px;
        font-weight: normal;
      }
    }
  }

  .AgencyProfileWrapper {
    .slider-div {
      .slider {

        .MuiSlider-thumb,
        .MuiSlider-track {
          @extend .text-blue;
        }
      }
    }

    .form-control-label {
      .radio-button {
        svg {
          @extend .text-blue;
        }
      }
    }
  }

  //career
  .submitBtn {
    @extend .btncancel;
  }

  .switchColor {
    .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
      background-color: $app-primary-purplish-blue-theme  !important;
    }
  }

  .MuiSwitch-root {
    .Mui-checked {
      @extend .text-blue;
    }

    .MuiSwitch-colorPrimary {
      .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
        @extend .searcgLbl;
      }
    }
  }

  .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
    background-color: $app-primary-purplish-blue-theme  !important;
  }

  .btncancel {
    color: $app-primary-purplish-blue-theme;
    border: 1px solid $app-primary-purplish-blue-theme;
  }

  .btnsave {
    @extend .bg-border;

    &:disabled {
      background-color: $app-primary-purplish-blue-theme;
    }

    &:hover {
      @extend .bg-border;
    }
  }

  .states-table {
    thead {
      tr {
        th {
          background-color: $app-primary-purplish-blue-theme;
          color: $white  !important;
        }
      }
    }
  }

  .MuiCheckbox-colorPrimary {
    @extend .text-blue;
  }


  .post-signup-wrapper,
  .pre-signup-wrapper {

    .sign-up-head,
    .btn-submit .emailverification-title {
      @extend .text-blue;
    }

    .btn {
      @extend .bg-border;
    }

    .btn-submit {
      background-color: $app-primary-purplish-blue-theme;

      &:hover {
        background-color: $app-primary-purplish-blue-theme;
      }
    }

    #Next {
      @extend .bg-border;
    }

    .back {
      @extend .cancel-button;
      @extend .text-blue;
    }

    .div {
      @extend .back;
    }

    .btn-primary:not(:disabled):not(.disabled):active {
      background-color: $app-primary-purplish-blue-theme;
      border-color: $app-primary-purplish-blue-theme;
    }

    .curent {
      @extend .text-blue;
    }
  }

  .login-btn {
    @extend .background-color;

    &:active {
      @extend .background-color;
    }

    &:disabled {
      &:hover {
        @extend .background-color;
      }
    }
  }

  .background-color {
    @extend .bg-border;
  }

  .text-blue {
    color: $app-primary-purplish-blue-theme  !important;
  }

  [type='checkbox'].filled-in:checked+span:not(.lever):after {
    @extend .background-color;
  }

  [type='checkbox'].filled-in:disabled:checked+span:not(.lever):after {
    @extend .bg-border;
  }

  .btn-file .inputfile+label {
    color: $app-primary-purplish-blue-theme;
    border: 1px solid $app-primary-purplish-blue-theme;
  }

  .download-link-div {
    color: $app-primary-purplish-blue-theme;
    border: 1px solid $app-primary-purplish-blue-theme;
  }

  .MuiSlider-root {
    @extend .text-blue;
  }

  .cancel-button {
    border: 1px solid $app-primary-purplish-blue-theme;
  }

  .DatePickerComponent {
    width: 100% !important;

    div {
      div {
        button {
          @extend .text-blue;
        }
      }
    }
  }

  .checked-check-box [type='checkbox'].filled-in:checked+span:not(.lever):after {
    @extend .background-color;
  }

  .button-continue {
    background-color: $app-primary-purplish-blue-theme;

    &:hover {
      background-color: $app-primary-purplish-blue-theme;
    }
  }

  .btnStyle {
    @extend .btncancel;
    margin-left: 7px;
    padding: 4px 8px;
    font-weight: 400;

    &:hover {
      @extend .btncancel;
    }
  }

  .imperson-icon {
    background-image: url(../../assets/images/impersonicon-purplish-blue.svg);
    background-size: 33px;
    padding: 18px;
    background-repeat: no-repeat;
    z-index: 1;
    position: absolute;
    top: 15px;
    cursor: pointer;
  }

  .impersion-pulse-icon {
    background-image: url(../../assets/images/impersonicon-purplish-blue.svg);
    background-repeat: no-repeat;
    z-index: 1;
    position: absolute;
    top: 12px;
    padding: 18px;
    background-size: 37px;
    right: 8px;
  }

  .pulse {
    box-shadow: 0px 7px 20px hsla(0, 0%, 0%, 0.2);
    animation: beat $duration $timing 0s infinite;
    border-radius: 50%;
  }

  @keyframes beat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }
  }

  @keyframes beat-before {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.15);
    }
  }

  @keyframes beat-after {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.3);
    }
  }

  .login-container {
    .btn-login {
      background: $app-primary-purplish-blue-theme;
      border: 1px solid $app-primary-purplish-blue-theme;

      &:active {
        background: $app-primary-purplish-blue-theme;
        border: 1px solid $app-primary-purplish-blue-theme;
      }

      &:hover {
        background: $app-primary-purplish-blue-theme  !important;
        border: 1px solid $app-primary-purplish-blue-theme  !important;
      }
    }

    .card-content {
      a {
        color: $app-primary-purplish-blue-theme;
      }
    }

    .sign-up-head {
      color: $app-primary-purplish-blue-theme;
    }
  }

  .white-button {
    @extend .text-blue;
  }

  //roles
  .rolesWraper {
    .rolesCardDiv {

      .editIcon,
      .backIcon {
        background: $app-primary-purplish-blue-theme;
      }

      .RoleexpansionPanelWrap {
        .lblAgentDir {
          @extend .text-blue;
        }
      }

      .switchOn:nth-child(1) {
        @extend .text-blue;

        .Mui-disabled {
          @extend .text-blue;
        }
      }
    }
  }

  //new-quote
  .new-quote-model-dialog {
    .label-box-select {
      border: 1px solid $app-primary-purplish-blue-theme;

      &:hover {
        border: 1px solid $app-primary-purplish-blue-theme;
      }

      .Mui-checked {
        svg {
          color: $app-primary-purplish-blue-theme;
        }
      }
    }

    .btn-continue {
      background: $app-primary-purplish-blue-theme;

      &:hover {
        background: $app-primary-purplish-blue-theme;
      }
    }
  }

  .widgetbtn {
    border-radius: 5px !important;
    background-color: $app-primary-purplish-blue-theme  !important;
    color: $white  !important;
    font-size: 16px !important;

    &:hover {
      background: $app-primary-purplish-blue-theme  !important;
    }
  }

  .purchansebtn {
    background-color: $app-primary-purplish-blue-theme  !important;

    &:hover {
      background: $app-primary-purplish-blue-theme  !important;
    }
  }

  .QuoteConnectorWrap {
    .btnAddNow {
      background-color: $app-primary-purplish-blue-theme;

      &:hover {
        background-color: $app-primary-purplish-blue-theme;
      }
    }
  }

  .checkedIcon {
    background-color: $app-primary-purplish-blue-theme;
    background-image: linear-gradient(180deg,
        hsla(0, 0%, 100%, 0.1),
        hsla(0, 0%, 100%, 0));

    input:hover~ {
      background-color: $app-primary-purplish-blue-theme;
    }
  }

  .radio-button-div {
    .MuiRadio-colorPrimary.Mui-checked {
      color: $app-primary-purplish-blue-theme;
    }
  }

  .calender-icon {
    button {
      svg {
        color: $app-primary-purplish-blue-theme;
      }
    }

    .MuiInput-underline.Mui-disabled:before {
      border-bottom-style: solid;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid $app-primary-purplish-blue-theme;
    }

    .MuiInput-underline:after {
      border-bottom: none;
    }
  }

  .chipInputStep {
    >div {
      &::before {
        border-bottom-style: solid;
      }
    }
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid $app-primary-purplish-blue-theme;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $app-primary-purplish-blue-theme;
  }

  .table-toolbar {
    .filter-wrap {
      .css-1pahdxg-control {
        border: none;
        border: 1px solid $app-primary-purplish-blue-theme;
        border-radius: 5px;
        box-shadow: none;
      }

      .css-1wa3eu0-placeholder {
        color: $app-primary-purplish-blue-theme;
      }

      .css-g1d714-ValueContainer {
        .css-1rhbuit-multiValue {
          background-color: $app-header-light-sky-blue;

          .css-12jo7m5 {
            color: $app-primary-purplish-blue-theme;
          }

          .css-xb97g8 {
            color: $app-primary-purplish-blue-theme;

            &:hover {
              background-color: $app-header-light-sky-blue;
            }
          }
        }
      }

      .css-tlfecz-indicatorContainer {
        color: $app-primary-purplish-blue-theme;

        svg {
          color: $app-primary-purplish-blue-theme;
        }
      }

      .css-1gtu0rj-indicatorContainer {
        color: $app-primary-purplish-blue-theme;
      }

      .filter-button {
        color: $app-primary-purplish-blue-theme;
        margin: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px;
        background-color: #fff;
      }
    }
  }

  .start-incentive-box-wrap {
    border: 2px solid $app-primary-purplish-blue-theme;

    .click-here-btn {
      background: $app-primary-purplish-blue-theme;
    }
  }

  .add-edit-incentive-wrap {
    .intencive-wrap {
      .animation-alignment-grid {
        .animation-pos {
          .checkbox-label {
            .Mui-checked {
              +.MuiFormControlLabel-label {
                @extend .text-blue;

                &.Mui-disabled {
                  color: rgba(0, 0, 0, 0.38) !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .credential-stepper {
    .MuiStepIcon-root.MuiStepIcon-completed {
      color: $app-primary-purplish-blue-theme;
      background-color: $app-primary-purplish-blue-theme;
    }
    .MuiStepIcon-root.MuiStepIcon-active {
      color: $app-primary-purplish-blue-theme;
    }
    .MuiStepConnector-line {
      border-color: $app-primary-purplish-blue-theme;
      opacity: 0.4;
    }
    .MuiStepLabel-root.Mui-disabled {
      .MuiStepIcon-root {
        color: $app-primary-purplish-blue-theme;
        opacity: 0.4;
      }
    }
  }

  .qc-wrap {
    .qc-content {
      .number-wrap,
      .get-start-button {
        background: $app-primary-purplish-blue-theme;
      }
    }

    .blue-btn {
      background-color: $app-primary-purplish-blue-theme;
    }
  }

  .product-selection-wrap {
    .tab-list {
      .Mui-selected {
        color: $app-primary-purplish-blue-theme;

        .MuiTab-wrapper {
          border-bottom: 1px solid $app-primary-purplish-blue-theme;
          width: auto;
        }
      }

      .MuiTabs-indicator {
        background-color: transparent;
      }
    }
  }

  .product-view {
    .lob-option {
      .checkbox-div {
        .border-color {
          border: 1.5px solid $app-primary-purplish-blue-theme;
        }
      }
    }
  }

  .carrier-callout-wrap {
    .lob-product-wrap {
      .product-view {
        border: 2px solid $app-primary-purplish-blue-theme;
      }
    }

    .blue-button {
      background-color: $app-primary-purplish-blue-theme;
    }

    .carousel-wrapper {
      .prev-icon,
      .next-icon {
        color: $app-primary-purplish-blue-theme;
      }
    }
  }
  .confirmation-modal-wraper {
    .confirm-text {
      .MuiCheckbox-colorSecondary.Mui-checked {
        color: $app-primary-purplish-blue-theme;
      }
    }
  }
  .dialog-footer {
    .confirm-btn {
      background-color: $app-primary-purplish-blue-theme;
    }
  }
  .blue-button {
    background-color: $app-primary-purplish-blue-theme;
    color: #fff;
    border: 0;
    &:hover {
      background: $app-primary-purplish-blue-theme;
    }
  }

  .product-view {
    &:hover {
      border-color: $app-primary-purplish-blue-theme;
    }
  }
  .devider-ht {
    border: 1px solid $app-primary-purplish-blue-theme;
    height: 22px;
    &.mleft {
      margin-right: 22px;
      margin-left: 14px;
    }
    &.mright {
      margin-right: 33px;
      margin-left: -9px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  .sign-out-btn {
    border-radius: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 20px;
    color: $app-primary-purplish-blue-theme;
    border-left: 2px solid $app-primary-purplish-blue-theme;

    @media (max-width: 992px) {
      display: none;
    }

    .MuiIconButton-label {
      font-size: 14px;
    }

    &:hover {
      background-color: transparent;
      color: #fff;
      border-left: 2px solid $app-primary-purplish-blue-theme;
    }
  }
  .endorsement-form-wraper {
    .btn-submit {
      background: $app-primary-purplish-blue-theme;
      font-family: 'Manrope', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: $white;
    }
  }
  .white-endo-req-btn {
    border: 1px solid $app-primary-purplish-blue-theme;
    color: $app-primary-purplish-blue-theme;
  }

  .required {
    color: #f44336;
      }
      .clickhere-lbl {
        span {
          color:$app-primary-purplish-blue-theme;
        }
      }

    .reset-pwd-link-wrap {
      .center-pos {
        .link-reset {
          color: $app-primary-purplish-blue-theme;

          &:hover {
            color: $app-primary-purplish-blue-theme;
          }
        }
      }
    }
}

//end
