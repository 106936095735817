.w9-modal {
  text-align: center;

  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    width: 90%;
    max-width: 780px;
    background-color: #FFF5E9;
    border-radius: 4px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .close-icon {
    position: absolute;
    top: 22px;
    right: 26px;
    cursor: pointer;
  }

  .hero-image {
    width: 100%;
    max-width: 568px;
    margin: 72px 0 32px 0;
    user-select: none;
  }

  .heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
    padding: 0 20px;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 56px;
    padding: 0 20px;
  }

  .action-card {
    display: flex;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 0 0 4px 4px;

    .cta {
      margin: 0;
    }
  }
}
