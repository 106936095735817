.app-logo-static img {
  height: auto;
  max-width: 250px;
  cursor: pointer;
}
.flex-class {
  display: flex;
  width: 70%;
  justify-content: end;
  align-items: center;
}
.mobile-div {
  width: 100%;
  display: flex;
}
.app-logo img {
  height: auto;
  max-width: 160px;
  cursor: pointer;
}

.right-20 {
  right: 20px;
}

.right-4 {
  right: -4;
}

.notificationIcon {
  position: absolute !important;
  right: 3% !important;
  z-index: 999999999 !important;

  span.badgeDot:after {
    display: block;
    position: absolute;
    content: '';
    right: 2px;
    top: -10px;
    z-index: 10;
    width: 8px;
    height: 8px;
    background-color: #f44336;
    border-radius: 50%;
  }
}

#simpleMenu,
.simpleMenu {
  border: 1px solid red !important;
  width: 350px !important;
  height: 300px !important;
  background-color: rgb(129, 109, 109);
  position: absolute !important;
  top: 10% !important;
  left: 5% !important;
}

.btn-stop-wrap {
  display: flex;
  justify-content: center;

  .btn-stop {
    background-color: #d00606 !important;
    color: white !important;
    min-width: 26px !important;
    padding: 0px 7px !important;
    height: 20px !important;
    font-size: 12px !important;
  }
}
.mob-wrap {
  display: none;
}

.impersonate-margin {
  margin: 15px;
  display: none;

  .padding-16 {
    padding: 16px;
    padding-bottom: 16px !important;
  }
}

.header__new-quote-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-left: 0;

  &--impersonate {
    margin-right: 50px;
  }

  &--show::before {
    content: '';
    width: 1px;
    height: 22px;
    background-color: #A3CBF8;
    margin-right: 16px;
  }
}

//For IE
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .notificationIcon {
    top: 10%;

    span.badgeDot:after {
      right: 3px;
    }
  }
}

@media (min-width: 1200px) {
  .app-logo {
    position: absolute;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  .right-25 {
    right: 30%;
  }
  .right-15 {
    right: 17%;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .app-logo {
    position: inherit;
  }
}

@media (max-width: 991px) {
  .impersonate-margin {
    display: block;
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .app-logo {
    position: absolute;
    top: 15px;
  }
}

