@import '../../styles/color-theme-variable.scss';

.text-orange {
  color: $fc-Orange;
}

input:internal-autofill-selected {
  background-color: transparent !important;
}

.reset-pwd-link-wrap {
  .center-pos {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 320px;
  }
}

.reset-link {
  text-decoration: underline;
}

.mb-10 {
  margin-bottom: 10px;
}

.grey-toggle {
  .MuiSwitch-root {
    height: 40px;
  }
}

.padding-30 {
  padding: 24px;
}

.post-signup-wrapper {
  .disabled {
    background-color: #bdbdbd;
    cursor: default;
  }

  .small-label {
    font-family: $Nunito-font-family;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: $color-text-grey;
  }

  .signin-form {
    .form-width {
      max-width: 380px;
      width: 100%;

      .form-card {
        padding-bottom: 0px;
        background: #fff;
        border-radius: 8px;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
        box-shadow: none;
        .password-login {
          .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 2px solid #2196f3;
          }
          .MuiInput-underline:before {
            border-bottom: 1px solid #ccc;
          }
        }
        .form-card-content {
          padding: 40px;
          border-bottom: 1px solid #dfdfda;

          .heading {
            .login-label {
              font-family: $Nunito-font-family;
              font-weight: 700;
              font-size: 20px;
              line-height: 140%;
              color: $color-text-grey;
              margin-bottom: 40px;
            }
          }

          .col-padding-0 {
            padding: 0;
          }

          .mb-40 {
            margin-bottom: 40px;
          }

          .mb-24 {
            margin-bottom: 44px;
          }

          .input-field {
            label {
              @extend .small-label;
            }
          }

          .forgotLink {
            font-family: $Nunito-font-family;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            text-decoration: none !important;
          }
        }

        .login-button-wrap {
          height: 92px;
          justify-content: space-around;
          margin-left: 40px;
          margin-right: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .mob-view {
          flex-direction: row;
        }

        .signup-text-label {
          font-family: 'Nunito Sans';
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          color: #5b5d73;
          width: 168px;
          margin-top: 10px;

          .padding-left-1 {
            padding-left: 0.25rem;
          }

          span {
            cursor: pointer;
          }
        }

        .login-btn {
          width: 100px;
          height: 40px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          border-radius: 20px;
          font-family: $Nunito-font-family;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: $white;
          border: none;

          &.disable {
            background-color: #bdbdbd;
            cursor: default;
          }
        }
      }
    }
  }

  .signup-content {
    margin-top: 50px;

    .signup-pass {
      .MuiInput-underline:before {
        border-bottom: 1px solid #ccc;
      }
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 2px solid #2196f3;
      }
    }
    .flex-center {
      display: flex;
      justify-content: center;
    }

    .signup-wrap {
      text-align: center;
      margin-bottom: 32px;

      .fc-logo {
        width: 220px;
        height: 18.33px;
        margin-bottom: 24px;
        text-align: center;
      }

      .paragraph-text {
        font-family: 'Nunito Sans';
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #525252;
        margin-bottom: 15px;
        margin-top: 15px;

        .display-block-lbl {
          display: block;
        }
      }
      .heading-text {
        font-family: 'Nunito Sans';
        font-weight: 600;
        font-size: 36px;
        line-height: 140%;
        text-align: center;
        color: #2f3440;
        margin-bottom: 0px;

        .display-block-lbl {
          display: block;
        }
      }
      .path-image {
        float: right;
      }
    }

    .helper-text {
      font-family: 'Nunito Sans';
      font-weight: 400;
      font-size: 10px;
      line-height: 140%;
      color: #a5a4a1;
      margin-top: 4px;
      margin-bottom: 0px;
    }

    .mb-17 {
      margin-bottom: 17px;
    }

    .carrier-logo-grid {
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 50px;
      display: flex;
      flex-wrap: inherit;

      .carrier-logo-wrap {
        width: 30%;
        display: contents;

        .carrier-logo-div {
          margin-right: 8px;
          margin-left: 8px;
          margin-bottom: 16px;
          width: 100px;
          height: 60px;
        }
      }
    }

    .error-msg {
      font-size: 11px;
      color: #bf0e08;
      margin-bottom: 0px;
    }
  }

  .recover-pwd-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .recover-password-form {
      width: 360px;

      .p-40 {
        padding: 40px;
        padding-bottom: 0px;
      }

      .recover-password-heading {
        font-family: $Nunito-font-family;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: $color-text-grey;
        margin-bottom: 40px;
      }

      .send-request-button {
        width: 94px;
        height: 40px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        font-family: $Nunito-font-family;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        border: none;
        margin-top: 16px;
        margin-bottom: 20px;

        &.disable {
          background-color: #bdbdbd !important;
          cursor: default;
        }
      }

      .input-field {
        margin-bottom: 60px;

        label {
          @extend .small-label;
          left: 0;
        }
      }

      .border-bottom {
        border-bottom: 1px solid #dfdfda;
      }
    }
  }

  .change-password-form {
    .change-pwd-card {
      width: 360px;

      .change-pwd-content {
        padding: 40px;
        padding-bottom: 20px;

        .input-field {
          margin-bottom: 40px;

          label {
            @extend .small-label;
          }
        }

        .chnage-pwd-heading {
          font-family: $Nunito-font-family;
          font-weight: 700;
          font-size: 20px;
          line-height: 140%;
          color: $color-text-grey;
          margin-bottom: 16px;
        }

        .chnage-pwd-info-label {
          font-family: $Nunito-font-family;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          color: $color-text-grey;
          margin-bottom: 40px;
        }
      }

      .change-pwd-action {
        padding: 16px;

        .update-password-button {
          width: 96px;
          height: 40px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          border-radius: 20px;
          border: none;
          font-family: $Nunito-font-family;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: $white;

          &.disable {
            background-color: #bdbdbd;
            cursor: default;
          }
        }
      }
    }
  }
}

.android-switch,
.switch-toggle,
.grey-toggle {
  .MuiSwitch-track {
    opacity: 0.2 !important;
  }

  .Mui-checked + .MuiSwitch-track {
    opacity: 0.9 !important;
  }

  .MuiSwitch-thumb {
    color: $white;
  }

  .MuiSwitch-root {
    height: 40px;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #a5a4a1 !important;
  }
}

.app-signup-v {
  .MuiPickersDay-daySelected {
    color: $fc-Orange;
  }

  .agency-profile-header {
    width: 100%;
    max-width: 830px;
    margin: 0 auto;
  }

  .mob-doc-wrap {
    display: none;
  }

  .checked-check-box {
    label {
      svg {
        path {
          fill: $fc-Orange;
        }
      }

      .Mui-disabled {
        svg {
          path {
            fill: $color-text-grey !important;
          }
        }
      }

      .MuiTypography-root {
        font-size: 14px;
      }
    }

    &.step-7 {
      &:hover {
        background-color: #f9f9f9;
      }
    }
  }

  .grid-shodow {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    width: 100%;

    .left-content {
      max-width: 660px;
      width: 100%;

      .label-error-color {
        color: #bf0e08 !important;
      }

      .error-border {
        border-bottom-color: #bf0e08;

        input {
          border-bottom-color: #bf0e08;
        }
      }

      .tax-checked-check-box {
        border: 1px solid $fc-Orange;
        border-radius: 4px;
        padding: 6px;
        height: 52px;
        width: 270px;

        .license-checkbox {
          font-family: $Nunito-font-family;
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          color: #525252;
        }

        span {
          cursor: pointer;
        }

        svg {
          path {
            fill: $fc-Orange;
          }
        }
      }

      .tooltipIcon {
        width: 16px;
        height: 16px;
      }

      .switch-toggle {
        .mr-36 {
          margin-right: 36px;
        }
      }

      .MuiPaper-rounded {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      .signup-steps-wrap {
        height: 100%;

        .post-signup-forms {
          .card-content {
            padding: 25px 25px 16px 25px;
          }
        }
      }

      .text-label,
      label {
        font-family: $Nunito-font-family;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: $color-text-grey;
      }

      .step-5 {
        height: 100%;
      }

      .upload-orange {
        display: contents;
      }

      .upload-orange-black {
        @extend .upload-orange;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 9px;

        .document-btn-file {
          width: 173px;
        }

        .btn-file .inputfile + label {
          border: 1px solid transparent;
          background-color: #fff5e9 !important;
        }

        .download-link {
          margin-top: 0px;
        }
      }

      .upload-orange {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 9px;

        .document-btn-file {
          min-width: 209px;
          height: 32px;
          background: #fff5e9;
          border-radius: 20px;

          .btn-file .inputfile + label {
            border: none;
          }
        }

        .grey-text {
          color: #333333;
          font-family: 'Nunito Sans';
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          text-transform: uppercase;
        }

        .download-link {
          margin-top: 0px;
        }

        .download-link {
          span {
            color: #333333;
            text-transform: uppercase;
            font-size: 12px;
          }
        }
      }

      .p-15 {
        padding: 10px;
      }

      .step-7 {
        margin-bottom: 40px;
        border: 1px solid $fc-Orange;
        border-radius: 4px;
        max-width: 365px;
        height: 52px;

        span {
          font-weight: 600;
        }
      }

      .checked-check-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 6px;

        label {
          margin: 0;
        }

        .MuiFormControlLabel-root {
          margin-left: 0px;
        }

        span {
          font-size: 14px;
          line-height: 140%;
          color: #525252;
          font-family: 'Nunito Sans', sans-serif;
        }

        svg {
          path {
            fill: $fc-Orange;
          }
        }
      }

      .page-agreement-wrapper {
        .agreement-form {
          padding: 40px;
          padding-bottom: 0px;
        }
      }
    }

    .full-width {
      max-width: none;
      width: 100%;
    }

    .right-content {
      max-width: 170px;
      width: 100%;

      .fast-start-wrap {
        background-color: $light-oranage;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .agency-social-proof-wrap {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .social-proof-card-wrap {
        @extend .agency-social-proof-wrap;
        background-color: $light-oranage;
        box-shadow: none !important;
        height: 100%;

        .card-content {
          padding: 24px 20px;
        }

        .que-label {
          font-family: $Nunito-font-family;
          line-height: 140%;
          color: $color-text-grey;
        }

        .social-heading {
          font-size: 14px;
          padding-bottom: 24px;
          margin-bottom: 0px;
          font-weight: 700;
          @extend .que-label;
        }

        .social-content {
          justify-content: space-between;

          .w-60 {
            width: 60%;
          }

          .w-30 {
            width: 30%;
          }

          .social-para {
            font-size: 12px;
            font-weight: 400;
            @extend .que-label;
          }

          .calender-icon {
            svg {
              color: $fc-Orange;
              width: 72px;
              height: 72px;
            }
          }

          .social-content-footer {
            display: block;
            margin-top: 20px;

            .mb-16 {
              margin-bottom: 16px;
            }

            .logo {
              background: #ffffff;
              border-radius: 4px;
              justify-content: center;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
              padding: 10px;
            }

            .lbl-auther {
              font-weight: 700;
              font-family: $Nunito-font-family;
              font-size: 11px;
              line-height: 140%;
              color: $fc-Orange;
              margin-right: 10px;
              margin-bottom: 16px;
            }

            .lbl-state {
              @extend .lbl-auther;
              font-weight: 400;
              color: #273e57;
              text-transform: uppercase;
            }
          }
        }

        .blue-card {
          background: #4696ec;
        }
      }
    }
  }

  .position-wrap {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .signup-btn-action-card {
    padding: 16px 20px;
    border-top: 1px solid #dfdfda;

    .button-style {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 16px;
      font-size: 12px;
    }

    .white-back-button {
      @extend .button-style;
      background: #ffffff;
      border: 1px solid #f2f2f2;
      color: #333333;

      @media (max-width: 767px) {
        width: 40px !important;
        padding: 0px 20px;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
        background: #fff url(../../assets/images/back-btn-icon.svg) no-repeat
          center center;

        span {
          display: none;
        }
      }
    }

    .orange-next-button {
      @extend .button-style;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border: none;
      margin: 0;
      background-color: $fc-Orange;
      color: $white;
      margin-left: auto;
    }

    .disable-button-grey {
      @extend .button-style;
      background: #bdbdbd;
      box-shadow: 0;
      border-radius: 20px;
      cursor: default;
      border: 0;
      color: #fff;
    }
  }
}

.signup-process-wrapper {
  max-width: 878px;
  margin: 0 auto;

  .error-msg {
    font-size: 11px;
    line-height: 140%;
    color: #bf0e08;
    margin: 3px 0px 4px 0px;
  }
}

.pre-signup-wrapper,
.post-signup-wrapper {
  font-family: $Nunito-font-family;
  font-size: 14px;
  color: #373737;

  .container {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 23px;
    font-family: $Nunito-font-family;
  }

  h6 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: $Nunito-font-family;
    color: #525252;
  }

  .signUpHeader {
    display: flex;
    padding: 20px 30px 0px 30px;

    h6 {
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 700;
      margin-right: 10px;
    }

    svg {
      font-size: 16px;
      color: $fc-Orange;
      cursor: pointer;
    }
  }

  .app-header {
    z-index: 1;
  }

  .lbl {
    font-size: 12px;
  }

  .details-head {
    font-family: $Nunito-font-family;
    font-weight: normal;
    color: #373737;
    font-size: 16px;
    color: #4696ec;
    font-weight: 700;
    max-width: 830px;
    margin: 0 auto;

    .big-head {
      font-weight: bold;
      color: #525252;
      position: relative;
      display: flex;

      .info-icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin: -5px 0px 0px 10px;
      }
    }
  }

  .sign-up-head {
    font-size: 20px !important;
    color: #0084c2;
    margin-bottom: 30px;
    font-family: $Nunito-font-family;
  }

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }

  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show > .nav-link {
    color: #0084c2 !important;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #373737 !important;

    padding: 0 15px;
  }

  .navbar-light .navbar-nav .nav-link:hover {
    color: #0084c2 !important;
  }

  /* comon style */
  .post-signup-forms {
    .row {
      margin-bottom: 20px;
    }
  }

  .post-form-2 .jumbotron {
    padding: 20px;
    background: #fff5e9;
  }

  .card {
    border-radius: 0px;
  }

  .post-signup-agency .card .card-content {
    padding: 35px 40px;
  }

  .post-signup-agency {
    padding-bottom: 5rem;
    padding-top: 40px;

    .agency-profile-heading {
      font-family: $Nunito-font-family;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: $color-text-grey;
      margin-bottom: 24px;
    }

    height: 100%;

    .license-step {
      button {
        background-image: url('../../assets/images/calendar-fill.svg');
        width: 20px;
        height: 20px;
        right: 18px;
        background-repeat: no-repeat;
        bottom: 8px;
      }

      svg {
        display: none;
      }
    }
  }

  .post-sign-up-ul {
    padding-inline-start: 0px !important;

    .disble-field {
      cursor: default !important;
    }
  }

  .post-sign-up-ul li {
    list-style-type: none;
    margin-bottom: 10px;
  }

  .post-sign-up-ul label {
    color: #373737;
  }

  .post-signup-forms .input-field {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .post-signup-forms label {
    font-size: 14px;
    color: #525252;
  }

  .btn-wrap {
    justify-content: flex-end;
  }

  input:not([type]),
  input[type='text']:not(.browser-default),
  input[type='password']:not(.browser-default),
  input[type='email']:not(.browser-default),
  input[type='url']:not(.browser-default),
  input[type='time']:not(.browser-default),
  input[type='date']:not(.browser-default),
  input[type='datetime']:not(.browser-default),
  input[type='datetime-local']:not(.browser-default),
  input[type='tel']:not(.browser-default),
  input[type='number']:not(.browser-default),
  input[type='search']:not(.browser-default),
  textarea.materialize-textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    outline: none;
    height: 2.6rem;
    width: 100%;
    font-size: 16px;
    color: #525252;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s, border 0.3s;
    transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
  }

  input:focus,
  select:focus,
  .MuiInput-input:focus,
  input:hover,
  select:hover,
  .MuiInput-input:hover {
    border-bottom: solid 2px #2196f3 !important;
  }

  .select-wrapper .select-dropdown {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    color: #373737;
    font-family: $Nunito-font-family;
    height: 2.6rem;
    line-height: 3rem;
    width: 100%;
    font-size: 15px;
    padding: 0;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
  }

  .card {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .btn-proceed {
    background: #0084c2;
    border: 1px solid #0084c2;
    color: #fff;
    width: 100%;
    float: left;
    border-radius: 4px;
    font-weight: 400;
    max-width: 132px !important;
    margin-left: 0px !important;

    &:hover {
      color: #fff !important;
    }
  }

  .back {
    background: #fff;
    border: 1px solid #0084c2;
    color: #0084c2;
  }

  .signin {
    input[type='text']:not(.browser-default) {
      font-family: $Nunito-font-family;
      font-size: 16px;
    }

    #password {
      font-size: 20px;
    }
  }

  .btn {
    padding: 8px 22px;
    letter-spacing: 0.5px;
    font-weight: 400;

    border-radius: 4px !important;
    font-size: 16px !important;
    font-family: $fontFamily;

    &:hover,
    &:focus {
      box-shadow: #ccc 0px 8px 24px !important;
    }
  }

  .profile-ul {
    padding-inline-start: 0px !important;
  }

  .required {
    margin-left: 2px;
  }

  .div {
    position: initial;
    overflow: hidden;

    background: #fff;
    border: 1px solid #0084c2;
    color: #0084c2;
    border-radius: 14px !important;

    height: 32px;

    label {
      max-width: 90%;
      padding-left: 14px;
      text-align: center;

      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;

      margin-top: 0px;

      svg {
        margin-right: 0.25em;
      }
    }
  }

  .input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  .upload-icon {
    padding-left: 10px;
    padding-right: 10px;
  }

  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #0084c2;
    border-color: #0084c2;
    color: #fff;
  }

  nav .nav-wrapper {
    position: relative;
    height: 100%;
  }

  .input-field > label {
    color: #373737;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    cursor: text;
    -webkit-transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, color 0.2s ease-out;
    transition: transform 0.2s ease-out, color 0.2s ease-out,
      -webkit-transform 0.2s ease-out;
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    text-align: initial;
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }

  .input-field > label:not(.label-icon).active {
    -webkit-transform: translateY(-14px) scale(0.9);
    transform: translateY(-14px) scale(0.9);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .input-field label {
    left: 0.89rem;
    font-size: 15px;
  }

  .input-field {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .icon-block {
    padding: 0 15px;
  }

  .icon-block .material-icons {
    font-size: inherit;
  }

  .brand-logo img {
    width: 250px;
    vertical-align: middle;
    padding-left: 20px;
    padding-top: 5px;
  }

  [type='date']::-webkit-inner-spin-button {
    display: none;
  }

  [type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  [type='date']::placeholder {
    display: none;
  }

  .post-form-2 .material-icons {
    font-size: 18px;
  }

  .curent {
    color: #0084c2 !important;
  }

  .page-footer {
    padding-top: 0px;
  }

  .sign-up-form-field {
    height: 100%;
  }

  .section.no-pad-bot {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-submit {
    width: 100%;
    text-transform: capitalize;
    background: #0084c2;
    margin-top: 14px;
    font-family: $Nunito-font-family;
    padding: 9px !important;
    color: #fff !important;
    margin: 0 auto;
    border-radius: 0px;
  }

  .btn-submit:hover {
    width: 100%;
    text-transform: capitalize;
    background: #64b5f6;
    color: #fff;
    border-radius: 0px;
    font-family: $Nunito-font-family;
  }

  .btn-submit:focus {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .ex-img {
    width: 100%;
  }

  .footer-copyright p {
    margin: 0px;
  }

  .card .card-content {
    padding: 35px 65px;
  }

  .sign-up-form .row {
    margin-bottom: 0px;
  }

  .profile-ul li {
    list-style-type: disc !important;
    margin-left: 20px;
    margin-bottom: 10px;
    padding-left: 11px;
  }

  .profile-rw {
    padding-top: 20px;
  }

  .user-div i {
    color: #373737;
    float: right;
    display: block;
    font-size: 24px;
    height: 56px;
    line-height: 56px;
  }

  .black {
    background-color: #000000 !important;
  }

  .page-footer .footer-copyright {
    overflow: hidden;
    min-height: 50px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0px;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(51, 51, 51, 0.08);
  }
}

.mar-t-4 {
  margin-top: 5%;
}

.marin {
  margin-top: 15%;

  .side-content {
    margin-left: 5rem;
    color: #373737;

    .order-list {
      padding-inline-start: 0px;
    }
  }
}

@media (max-width: 959px) {
  .signUpLeftSection {
    order: 2;
  }

  .signUpRightSection {
    order: 1;
  }
}

@media (max-width: 768px) {
  .margin-top-22 {
    margin-top: 22px !important;
  }

  .post-signup-wrapper {
    .padding-0 {
      padding: 0px;
    }

    .card {
      .card-content {
        padding: 35px 20px;
      }
    }

    .post-form-2 {
      .jumbotron {
        padding: 20px 13px;
        text-align: justify;
      }
    }

    .post-signup-agency {
      .card {
        .card-content {
          padding: 35px 20px;
        }
      }

      .agency-profile-heading {
        padding-left: 13px;
      }
    }

    .btn {
      max-width: 115px;

      width: 100%;
    }
  }

  .post-signup-forms {
    .margin-bottom {
      margin-bottom: 10px;
    }
  }

  .app-signup-v {
    .position-wrap {
      position: inherit;
    }

    .grid-shodow {
      display: contents;

      .left-content {
        max-width: 100%;

        .page-agreement-wrapper {
          .agreement-form {
            padding: 0px;
          }
        }

        .MuiPaper-rounded {
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        .signup-btn-action-card {
          padding: 16px;
          position: fixed;
          width: 100%;
          z-index: 1;
          right: 10px;
          border-top: 0px;
          transition: top 0.2s ease;

          .white-back-button,
          .orange-next-button {
            width: 120px;
          }
        }
      }

      .signup-btn-action-card {
        padding: 16px;
        position: fixed;
        z-index: 1;
        right: 0;
        border-top: 0;
        width: 100%;
        transition: top 0.2s ease;
      }

      .right-content {
        width: 100%;
        max-width: 100%;

        .agency-social-proof-wrap {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .post-signup-wrapper {
    .heading-text {
      font-size: 8vw !important;
    }
    .signin-form {
      .form-width {
        .form-card {
          padding-bottom: 26px;

          .form-card-content {
            padding: 24px;
            padding-bottom: 0px;
          }

          .login-button-wrap {
            margin-left: 20px;
            margin-right: 20px;
            display: block;
          }

          .mob-view {
            display: flex;
            flex-direction: column-reverse;
            height: auto;
            margin-top: 23px;
          }

          .signup-text-label {
            width: 100%;
            margin-top: 23px;

            .padding-left-1 {
              padding-left: 0px;
            }

            .resp-d-block {
              display: block;
            }
          }

          .login-btn {
            margin: 0px;
            width: 100%;
          }
        }
      }
    }

    .signup-content {
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 25px;
      .signup-wrap {
        text-align: left;
        .paragraph-text,
        .heading-text {
          text-align: left;
          .display-block-lbl {
            display: contents;
          }
        }
      }
      .carrier-logo-grid {
        flex-wrap: inherit;
        justify-content: center;

        .carrier-logo-wrap {
          width: 30%;
          display: contents;

          .carrier-logo-div {
            margin-right: 8px;
            margin-left: 8px;
            margin-bottom: 16px;
            width: 100px;
            height: 60px;
          }
        }
      }
    }
  }

  .app-signup-v {
    .grid-shodow {
      .left-content {
        .tax-checked-check-box {
          height: inherit;
          width: 100%;
        }

        .page-agreement-wrapper {
          .agreement-form {
            padding-bottom: 22px;
          }
        }

        .button-style {
          width: 120px;
        }

        .step-7 {
          margin-bottom: 40px;
          border: 1px solid $fc-Orange;
          border-radius: 4px;
          max-width: 384px;
          width: 100%;
          height: 54px;
          margin: 0 auto;
        }

        .p-0-24 {
          padding: 0px 24px;
        }

        .upload-orange-black {
          display: block;
          margin-top: 20px;

          .download-link,
          .document-btn-file {
            width: 100%;
            margin-top: 20px;
          }
        }

        .upload-orange {
          .document-btn-file {
            min-width: 100%;
          }
        }
      }

      .mob-doc-wrap {
        display: block;

        .doc-label-wrap {
          width: 100%;
          height: 56px;
          background: #efefed;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          margin-bottom: 20px;

          .doc-name {
            font-family: $Nunito-font-family;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #525252;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
@media (min-width: 440px) {
  .path-image {
    margin-right: 14%;
  }
}

@media (max-width: 440px) {
  .path-image {
    margin-right: 5%;
  }
}
