/*Process Header Styles*/

.process-main-header {
 color: $black !important;
.logo-lg {
    padding-left: 20px;
    padding-top: 5px;
    img {
      width: auto;
      max-height: 52px;
    }
  }

  .ellipse-shape {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
    height: 100%;
    width: 560px;

    &:after {
      content: '';
      display: block;
      width: 870px;
      position: absolute;
      top: -20px;
      right: -300px;
      height: 280px;
      z-index: 1;
      background-color: rgba($white, 0.06);
      @include border-radius(50% 0 0 50%);
    }
  }

  & .search-bar input {
    @include transition($transition-base);
    background-color: rgba($white, 0.2);
    color: $white;
    & + .search-icon {
      top: 0;
      & i {
        color: rgba($white, 0.8);
      }
    }

    &:focus {
      background-color: $white;
      color: $body-color;
      + .search-icon {
        & i {
          color: rgba($body-color, 0.8);
        }
      }
    }
  }
}
@media (min-width:320px) and (max-width:415px) {
  .process-main-header {
    .signout-blue {
      margin-right: 0px;
      line-height: 16px;
      margin-left: 10px;
    }
  }
}