.menubar {
  .carrierIcon {
    height: 20px;
    width: 24px;
    vertical-align: middle;
  }

  .width-25 {
    width: 25px;
  }
}
.popover-wrap {
  top: -10px !important;
  left: 140px !important;
  .MuiPopover-paper {
    width: auto;
  }
  .triangle-left {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 29px solid #353B49;
    position: absolute;
    z-index: -1;
    top: 4px;
  }
  .popover-div {
    width: 200px;
    height: 66px;
    background: #353B49;
    color: #ffffff;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    line-height: 140%;
    display: flex;
    padding-right: 16px;
    margin-left: 10px;

    span {
      display: flex;
      align-items: center;

      svg {
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }
    @media (max-width:576px) {
      width: 100%;
      height: inherit;
    }
  }
}
.thirdLevel {
  padding-left: 15px;
}

.button {
  width: 113px;
  height: 26px;
  color: #ffffff !important;
  text-transform: uppercase !important;
  cursor: pointer;
  line-height: 19px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 5px;
}

.incentive-footer {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
}

.baseline-incentive-box-wrap {
  background-image: url(../../assets/images/strip-sign-of-las-vegas.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 9px;

  .incetive-container {
    background: rgba(0, 0, 0, 0.85);
  }

  .baseline-btn {
    @extend .button;
    background: #ff9a14;

    &:hover {
      background: #ff9a14;
    }
  }

  .font-style {
    font-family: 'Arial' !important;
    font-weight: 700;
    text-transform: uppercase;
  }

  .win-trip-lbl {
    @extend .font-style;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    margin-top: 43px;
  }

  .baseline-incentive-label {
    @extend .font-style;
    font-size: 34px;
    line-height: 39px;
    color: #ff9a14;
    margin-bottom: 75px;
  }

  .baseline-incentive-footer {
    @extend .incentive-footer;
    color: #ffffff;
    margin-top: 18px;
    margin-bottom: 29px;
  }
}

.start-incentive-box-wrap {
  background: linear-gradient(
    180deg,
    rgba(255, 237, 213, 0.5) 0.52%,
    rgba(255, 245, 228, 0.5) 56.48%,
    rgba(212, 230, 244, 0.5) 100%
  );
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 9px;
  border-radius: 4px;

  .walet-img {
    width: 120px;
    height: 120px;
  }

  .start-incentive-label {
    font-weight: 800;
    @extend .incentive-footer;
    color: #303030;
    margin-top: 7px;
  }

  .click-here-btn {
    @extend .button;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .start-incentive-footer {
    @extend .incentive-footer;
    color: #303030;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 10px;
  }
}

.steppers {
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 10000;
  height: 30%;
  background: transparent;
  margin-top: 40%;
  width: 100%;
  display: flex;
  padding: 14px;
  justify-content: space-between;
  align-items: center;

  .step-btn {
    display: flex;
    height: 100%;

    svg {
      color: #ff9a14;
      cursor: pointer;
    }

    &:hover {
      background: transparent;
    }
  }
}

.step-btn-left {
  width: 20%;
  position: absolute;
  top: 39%;
  button {
    &:hover {
      background-color: transparent;
    }
  }
}

.step-btn-right {
  width: 20%;
  position: absolute;
  top: 39%;
  right: 10%;
  button {
    &:hover {
      background-color: transparent;
    }
  }
}
.carrier-item {
  cursor: pointer;
  display: block;
  min-width: inherit !important;
  padding: 7px 35px 8px 15px;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  border-left: 5px solid #fff;
  &:hover {
    color: #ff9a14;
  }
  .nav-text {
    margin-left: 8px;
    vertical-align: middle;
    color: #6b7a93;
    &:hover {
      color: #ff9a14;
    }
  }
  .icon-floods {
    font-size: 20px;
    vertical-align: middle;
    color: #566276;
    &:hover {
      color: #FF9A14;
    }
  }
}

.producer-prev-container {
  margin: 9px 15px;

  .btn-click {
    width: 113px;
    height: 26px;
    font-weight: 700;
    line-height: 19px;
    text-transform: initial;
  }
  .step-btn-right,
  .step-btn-left {
    button {
      svg {
        color: #ff9a14;
      }
    }
  }
  .cursour-pointer {
    cursor: pointer;
  }
}

.react-swipeable-view-container {
  height: 100%;
  min-height: 280px;
}

.tooltip-label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #FFFFFF;
  margin-left: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 0px;
  display: flex;
  align-items: center;
  .close-icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}
@media (max-width:576px) {
  .carrier-store-tooltip {
    .MuiTooltip-popper {
      left: -123px;
    }
  }
  .tooltip-label {
    margin-left: 0px;
  }
}
@media (max-width:991px) {
  .carrierTooltip {
    .MuiTooltip-popper {
      left: 135px !important;
    }
  }
}
